<template>
  <div class="map-maker body-bcg">
    <div class="page-padding">
      <div class="flex justify-between items-end mb-25 map-maker__title">
        <div class="page-title mb-0">
          <icon-base
            :width="32"
            :height="32"
            :viewBox1="24"
            :viewBox2="24"
            iconName="mapMaker"
            iconColor="transparent"
            iconStroke="#4318ff"
            ><icon-map-maker
          /></icon-base>

          <div class="page-title__text">
            <h1>Map Maker</h1>
            <span>Full Library</span>
          </div>
        </div>

        <a-button
          type="primary"
          class="btn ml-auto flex items-center gap-5 justify-center"
          @click="showAddModal()"
        >
          <icon-base
            :width="17"
            :height="17"
            :viewBox1="24"
            :viewBox2="24"
            iconName="plus"
            iconStroke="transparent"
            ><icon-plus /></icon-base
          >Add Map</a-button
        >
      </div>
      <div class="box-wrapper">
        <div class="flex items-center">
          <h2 class="section-title mb-0">All Projects</h2>

          <a-dropdown
            :trigger="['click']"
            class="ml-auto mr-40 map-maker__download"
          >
            <a @click.prevent class="font-size-14 cursor-pointer">
              <font-awesome-icon icon="download" class="mr-5" /> What do I
              upload?
            </a>

            <template #overlay>
              <a-menu class="light-dropdown-menu">
                <a-menu-item key="0">
                  <a href="/mapMakerTemplates/Inputs.zip">SPSS example</a>
                </a-menu-item>
                <a-menu-item key="1">
                  <a href="/mapMakerTemplates/Example_Excel_Import.xlsx"
                    >Excel example</a
                  >
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>

          <div class="input-group input-group--small mr-5">
            <div
              class="position-r search-field"
              :class="{ focus: searchActive }"
            >
              <a-input
                placeholder="Search"
                style="width: 240px"
                v-model="searchParametars"
                class="filterPanel__search"
                @focus="searchActive = true"
                @blur="searchActive = false"
                :class="{
                  'filterPanel__search--active': searchParametars != '',
                }"
              >
                <a-icon
                  slot="addonAfter"
                  theme="filled"
                  type="close-circle"
                  v-show="searchParametars != ''"
                  @click="searchParametars = ''"
              /></a-input>
              <icon-base
                class="search-icon"
                :width="20"
                :height="20"
                :viewBox1="64"
                :viewBox2="64"
                iconName="searchThin"
                iconColor="transparent"
                iconStroke="#000"
                ><icon-search-thin
              /></icon-base>
            </div>
          </div>

          <a-dropdown
            :visible="dropdownVisible"
            :trigger="['click']"
            @visibleChange="handleVisibleChange"
            overlayClassName="filters-dropdown"
          >
            <a
              class="filters-dropdown-cta"
              :class="{ 'is-active': dropdownVisible }"
              @click="dropdownVisible = true"
            >
              <i
                v-if="
                  selectedCategoryFilters.length !== 0 || selectedDate !== ''
                "
              ></i>
              <icon-base
                :width="24"
                :height="24"
                :viewBox1="24"
                :viewBox2="24"
                iconName="filters"
                iconColor="transparent"
                iconStroke="#1C1C1C"
                ><icon-filters
              /></icon-base>
              Filters
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0">
                  <div class="input-group input-group--small">
                    <label>Category</label>
                    <a-select
                      @change="selectedCategoryFilters = $event"
                      placeholder="All Categories"
                      class="w-full"
                      mode="multiple"
                      :options="uniqueCategories"
                    >
                    </a-select>
                  </div>
                </a-menu-item>
                <a-menu-item key="1">
                  <div class="input-group input-group--small">
                    <label>Date</label>
                    <a-range-picker
                      @change="selectedDate = $event"
                      format="MM-DD-YYYY"
                    />
                  </div>
                </a-menu-item>
                <a-menu-item key="2">
                  <div class="flex justify-end gap-15 mt-5 mb-5">
                    <a-button
                      type="link"
                      @click="
                        (selectedCategoryFilters = []), (selectedDate = '')
                      "
                      >Reset Filters</a-button
                    >
                    <a-button type="primary" @click="dropdownVisible = false"
                      >Close</a-button
                    >
                  </div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <Loader
          v-if="loading"
          text="Loading your projects, please wait!"
          class="flex-1"
        ></Loader>
        <div
          v-else-if="paginatedProducts.length > 0"
          class="products-grid mt-30"
        >
          <MapMakerItem
            v-for="item in paginatedProducts"
            :key="item.projectID"
            :project="item"
            @delete-project="deleteProject(item.projectID)"
            @modify-project="setModifyProject(item)"
            @reset-project="resetProject(item)"
          ></MapMakerItem>
        </div>
        <div
          v-else-if="getProjectListTriggered"
          class="flex items-center justify-center flex-col flex-1 text-center"
        >
          <icon-base
            :width="60"
            :height="60"
            :viewBox1="32"
            :viewBox2="32"
            iconName="no-results"
            iconColor="#415262"
            iconStroke="#fff"
            class="mb-10"
            ><icon-no-results
          /></icon-base>
          <p class="mb-20">
            No projects found. <br />
            Please adjust your criteria or create a new study to get started.
          </p>
          <a-button
            type="primary"
            class="btn flex items-center gap-5 justify-center"
            @click="showAddMapModal = true"
          >
            <icon-base
              :width="17"
              :height="17"
              :viewBox1="24"
              :viewBox2="24"
              iconName="plus"
              iconStroke="transparent"
              ><icon-plus /></icon-base
            >Add Map</a-button
          >
        </div>
        <div
          class="flex justify-end mt-25"
          v-if="!loading && searchProjectsList.length > pageSize"
        >
          <a-pagination
            :current="currentPage"
            :pageSize="pageSize"
            show-less-items
            :total="searchProjectsList.length"
            @change="currentPage = $event"
          />
        </div>
      </div>
    </div>
    <!-- here the add modal gets called -->
    <a-modal
      title="Add Map"
      :visible="showAddMapModal"
      @cancel="
        (showAddMapModal = false), (chosenProject = null), (actionType = '')
      "
      :footer="null"
      :width="755"
      centered
      :maskClosable="false"
    >
      <AddMapModal
        v-if="showAddMapModal"
        @close-modal="
          (showAddMapModal = false), (chosenProject = null), (actionType = '')
        "
        @get-projects="getProjects(false)"
        :categoriesList="categoriesList"
        :countriesList="countriesList"
        :chosenProject="chosenProject"
        :actionType="actionType"
      ></AddMapModal>
    </a-modal>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconMapMaker from "@/components/icons/IconMapMaker.vue";
import IconSearchThin from "@/components/icons/IconSearchThin.vue";
import IconFilters from "@/components/icons/IconFilters.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconNoResults from "@/components/icons/IconNoResults.vue";
import MapMakerItem from "@/components/mapMaker/MapMakerItem.vue";
import AddMapModal from "@/components/mapMaker/AddMapModal.vue";
import Loader from "@/components/general/Loader.vue";
import * as wsUtils from "@/utils/wsUtils.js";

export default {
  name: "MapMaker",
  components: {
    IconBase,
    IconMapMaker,
    IconSearchThin,
    IconFilters,
    IconPlus,
    MapMakerItem,
    AddMapModal,
    Loader,
    IconNoResults,
  },

  data() {
    return {
      loading: false,
      searchParametars: "",
      searchActive: false,
      dropdownVisible: false,
      showClearAllFilters: false,
      showAddMapModal: false,
      getProjectListTriggered: false,
      projectsList: [],
      currentPage: 1,
      pageSize: 12,
      selectedCategoryFilters: [],
      selectedDate: "",
      categoriesList: [],
      countriesList: [],
      chosenProject: null,
      actionType: "",
    };
  },
  computed: {
    searchProjectsList() {
      const searchTerms = this.searchParametars.toLowerCase().split(" ");
      const startDate = this.selectedDate[0]
        ? new Date(this.selectedDate[0]._d)
        : null;
      const endDate = this.selectedDate[1]
        ? new Date(this.selectedDate[1]._d)
        : null;

      const filteredProjects = this.projectsList.filter((item) => {
        const itemDate = new Date(item.dateFielded);

        const isInCategoryFilter =
          this.selectedCategoryFilters.length === 0 ||
          this.selectedCategoryFilters.includes(item.category);
        const isInDateRange =
          !startDate ||
          !endDate ||
          (itemDate >= startDate && itemDate <= endDate);

        return isInCategoryFilter && isInDateRange;
      });

      return filteredProjects.filter((item) => {
        return searchTerms.every((term) => {
          return (
            item.projectName.toLowerCase().includes(term) ||
            item.market.toLowerCase().includes(term) ||
            item.category.toLowerCase().includes(term) ||
            item.brands.some((brand) => brand.toLowerCase().includes(term))
          );
        });
      });
    },
    paginatedProducts() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.searchProjectsList.slice(start, end);
    },
    uniqueCategories() {
      const categories = this.projectsList.map((item) => item.category);
      const uniqueCategories = [...new Set(categories)];

      return uniqueCategories.map((category) => ({
        value: category,
        label: category,
      }));
    },
  },

  methods: {
    handleVisibleChange(visible) {
      this.dropdownVisible = visible;
    },
    getProjects(showLoading = true) {
      this.getProjectListTriggered = true;
      if (showLoading) {
        this.loading = true;
      }
      const data = {
        wsName: "GetMapProjectList",
        data: {},
      };

      const getProjects = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getProjects.then((data) => {
        if (data.success) {
          this.projectsList = data.message;
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }

        this.loading = false;
      });
    },
    getCategories() {
      const data = {
        wsName: "GetCategory",
        data: {},
      };

      const getCategory = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getCategory.then((data) => {
        if (data.success) {
          this.categoriesList = data.message;
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Cannot get categories, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    getCountries() {
      const data = {
        wsName: "GetCountry",
        data: {},
      };

      const getCountry = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      getCountry.then((data) => {
        if (data.success) {
          this.countriesList = data.message;
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Cannot get countries, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    showAddModal() {
      if (this.categoriesList.length === 0 || this.countriesList.length === 0) {
        this.$notification["error"]({
          message: "Error!",
          description:
            "Cannot get categories or countries, please contact our support.",
          placement: "bottomLeft",
          duration: 5,
        });
      } else {
        this.showAddMapModal = true;
      }
    },
    deleteProject(projectId) {
      let _this = this;
      this.$confirm({
        title: "Delete project",
        content: () => <div>Are you sure you want to delete this project?</div>,
        onOk() {
          _this.deleteProjectConfirm(projectId);
        },
        okText: "Yes",
        onCancel() {},
      });
    },
    deleteProjectConfirm(projectId) {
      const data = {
        wsName: "DeleteMapProject",
        data: {
          projectId: projectId,
        },
      };

      const deleteProject = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      deleteProject.then((data) => {
        if (data.success) {
          this.$notification["success"]({
            message: "Well done!",
            description: "Project has been deleted successfully.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.getProjects();
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Deleting project failed, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
    setModifyProject(project) {
      this.chosenProject = project;
      this.actionType = "modify";
      this.showAddMapModal = true;
    },
    resetProject(project) {
      this.chosenProject = project;
      this.actionType = "reset";
      this.showAddMapModal = true;
    },
  },
  activated() {},
  created() {
    this.getProjects();
    this.getCategories();
    this.getCountries();
  },
  watch: {
    searchProjectsList() {
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-maker {
  min-height: 100%;
  .page-padding {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .box-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  &__download {
  }
}

.filters-dropdown .ant-dropdown-menu {
  width: 284px;
}
</style>
