<template>
  <div
    class="flex items-center justify-center data-info"
    :class="{ 'data-info--border-0': border0 }"
    :style="{
      paddingTop: `${paddingTop}px`,
      paddingBottom: `${paddingBottom}px`,
    }"
  >
    <a-icon type="info-circle" />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "DataInfo",
  props: {
    text: {
      type: String,
      default() {
        return "";
      },
    },
    paddingTop: {
      type: Number,
      default() {
        return 0;
      },
    },
    paddingBottom: {
      type: Number,
      default() {
        return 0;
      },
    },
    border0: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.data-info {
  display: flex;
  justify-content: center;
  padding: 50px;
  align-items: center;
  border: 1px solid #f4f4f4;
  font-size: 15px;
  color: #686868;
  .anticon {
    margin-right: 5px;
  }
  &--border-0 {
    border-width: 0;
  }
}
</style>
