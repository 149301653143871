<template>
  <div class="dashboard body-bcg">
    <div class="dashboard__content">
      <div class="page-title">
        <icon-base
          :width="32"
          :height="32"
          :viewBox1="512"
          :viewBox2="512"
          iconName="dashboard"
          ><icon-dashboard
        /></icon-base>

        <div class="page-title__text">
          <h1>Brand Operating System</h1>
          <span>Brand Operating System information</span>
        </div>

        <router-link class="page-title__link" to="/"
          >Solutions Hub <font-awesome-icon icon="arrow-right"
        /></router-link>
      </div>
      <p
        class="flex items-center justify-center dashboard__loader"
        v-if="loadingDashboard"
      >
        <i class="circle-loader"></i>Loading...
      </p>
      <div v-else>
        <div class="box-wrapper">
          <div
            class="section-info mb-35"
            v-if="
              dashboardDetailsInfo[0] &&
              dashboardDetailsInfo[0].BOSIntro &&
              dashboardDetailsInfo[0].BOSIntro !== ''
            "
          >
            <div v-html="this.dashboardDetailsInfo[0].BOSIntro"></div>
          </div>

          <div class="dashboard__box">
            <ul class="dashboard__tabs">
              <li
                v-for="item in user.BOSModules"
                :key="item.Id"
                @click="setActiveTab(item)"
                :class="{ 'is-active': item.Id === activeTab }"
              >
                {{ item.ModuleName }}
              </li>
            </ul>
            <div class="dashboard__tabs-content">
              <iframe :src="frameURL" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconDashboard from "@/components/icons/IconDashboard.vue";

export default {
  name: "BrandOperatingSystem",
  components: {
    IconBase,
    IconDashboard,
  },
  data() {
    return {
      user: null,
      activeTab: null,
      frameURL: null,
      dashboardDetailsInfo: [
        {
          AuthoringEnabled: false,
          ShelfLibraryEnabled: false,
          SupportEnabled: false,
        },
      ],
      loadingDashboard: true,
    };
  },
  computed: {},
  methods: {
    userDetails() {
      this.user =
        JSON.parse(localStorage.getItem("studioUser")) ||
        JSON.parse(jsUtils.readCookie("HS-STUDIO"));

      this.activeTab = this.user.BOSModules[0].Id;
      this.frameURL = this.user.BOSModules[0].ModuleURL;
    },
    setActiveTab(info) {
      this.activeTab = info.Id;
      this.frameURL = info.ModuleURL;
    },
    dashboardDetails() {
      const getDashboardDetails = new Promise((resolve) => {
        wsUtils.GetDashboardDetails(
          {
            userid: this.user.EncrypteduserID,
          },
          resolve
        );
      });

      getDashboardDetails.then((data) => {
        if (data.length !== 0) {
          this.dashboardDetailsInfo = data;
        }
        this.loadingDashboard = false;
      });
    },
  },
  created() {
    this.userDetails();
    this.dashboardDetails();
  },
  activated() {},
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__content {
    padding: 70px 77px;
    @media screen and (max-width: 980px) {
      padding: 70px 25px;
    }
  }
  &__loader {
    padding: 100px 0;
  }
  .ant-row {
    margin: 0 -10px 40px;
    flex-wrap: wrap;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-col {
    padding: 0 10px;
    margin-bottom: 20px;
    @media screen and (max-width: 1280px) {
      width: 50%;
      &.shelf-library {
        order: 3;
      }
      &.support {
        order: 2;
      }
    }
    @media screen and (max-width: 880px) {
      width: 100%;
      &.support {
        order: 1;
      }
      &.project-status {
        order: 2;
      }
    }
  }
  h2 {
    font-size: 15px;
    margin-bottom: 20px;
  }
  &__box {
    flex-direction: column;
    &--intro {
      background: #fff;
      padding: 20px 15px;
      border-radius: 6px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
      margin-bottom: 30px;
    }
    ::v-deep p {
      margin-bottom: 0;
    }
  }
  &__tabs {
    display: flex;
    padding: 0 0 0 1px;
    margin: 0 0 -1px;
    li {
      background: #f8f8f8;
      padding: 6px 14px;
      border-radius: 7px 7px 0 0;
      border: 1px solid #eaeaea;
      margin: 0 0 0 -1px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-weight: 500;
      &:hover {
        background: #fff;
      }
      &.is-active {
        background: #fff;
        border-bottom-color: #fff;
      }
    }
  }
  &__tabs-content {
    background: #fff;
    height: calc(100vh - 300px);
    border: 1px solid #eaeaea;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
