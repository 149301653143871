export default {
  methods: {
    formatPrice(price) {
      if (price === undefined || price === null || (typeof price === 'object' && Object.keys(price).length === 0)) {
        return " /";
      }
    
      if (price === 0) {
        return "0";
      }
    
      return price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    
  },
};
