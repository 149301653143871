<template>
  <div class="shelf-builder-library">
    <div class="shelf-builder-library__inner">
      <h1>Projects List</h1>
      <div class="shelf-builder-library__table">
        <div class="flex items-center shelf-builder-library__table-tools">
          <div
            class="shelf-builder-library__table-tools-search"
            :disabled="searchProjectsList.length === 0"
          >
            <font-awesome-icon icon="search" />
            <a-input
              placeholder="Search projects..."
              v-model="searchParametars"
              class="ant-input--dark"
              ref="searchParametars"
            />
          </div>
        </div>
        <a-table
          tableLayout="auto"
          :columns="columns"
          :row-key="(record) => record.cipID"
          :data-source="searchProjectsList"
          :loading="loading"
          :customRow="clickTableRow"
          :pagination="pagination"
          class="ant-table--dark"
        >
          <span
            slot="shelfCreated"
            slot-scope="record"
            class="shelf-created-status"
          >
            <font-awesome-icon
              :icon="record.IsShelfCreated ? 'check-circle' : 'minus-circle'"
            />
          </span>
          <span slot="shelfCreatedDate" slot-scope="record">
            {{ record || "/" }}
          </span>
          <span slot="projectName" slot-scope="record">
            {{ record || "/" }}
          </span>
          <span slot="status" slot-scope="record">
            {{ record || "/" }}
          </span>

          <span
            slot="action"
            class="flex gap-10 justify-center"
            slot-scope="record"
          >
            <template v-if="record.IsShelfCreated">
              <span class="row-caption">Open</span>
              <span class="row-caption row-caption--delete js-delete"
                >Delete</span
              >
            </template>
            <span v-else class="row-caption">Create Shelf</span>
          </span>
        </a-table>
      </div>
    </div>
    <a-modal
      title="Create New Project"
      :visible="createProjectModal"
      :footer="null"
      :width="390"
      @cancel="createProjectModal = false"
      centered
      class="ant-modal--dark"
    >
      <div class="large-field large-field--dark mb-25">
        <label>Project name</label>
        <a-input
          placeholder="Insert your project name"
          v-model="projectName"
          class="ant-input--dark b-radius-40"
          @keyup.enter="createProject"
          ref="projectName"
        />
      </div>
      <div class="flex justify-end">
        <a-button
          type="primary"
          @click="createProject"
          class="b-radius-40 flex items-center ant-btn--blue justify-center"
          style="width: 73px"
          :disabled="projectName.trim() === '' || createProjectLoading"
          ><Loader class="space-0" text="" v-if="createProjectLoading"></Loader>
          <span v-else>Create</span>
        </a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import * as jsUtils from "@/utils/jsUtils.js";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";

const columns = [
  {
    title: "Date",
    dataIndex: "createdAt",
  },
  {
    title: "CIP ID",
    dataIndex: "cipID",
  },
  {
    title: "CIP Title",
    dataIndex: "cipTitle",
  },
  {
    title: "Shelf Created",
    key: "IsShelfCreated",
    scopedSlots: { customRender: "shelfCreated" },
  },
  {
    title: "Shelf Created Date",
    dataIndex: "shelfCreatedDate",
    scopedSlots: { customRender: "shelfCreatedDate" },
  },
  {
    title: "Project Name",
    dataIndex: "projectName",
    scopedSlots: { customRender: "projectName" },
  },
  {
    title: "Status",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    scopedSlots: { customRender: "action" },
  },
];

export default {
  name: "ShelfBuilderLibrary",
  components: { Loader },
  data() {
    return {
      columns,
      searchParametars: "",
      projectList: [],
      loading: false,
      user: null,
      createProjectModal: false,
      createProjectLoading: false,
      projectName: "",
      pagination: {},
      activeCipId: null,
    };
  },
  computed: {
    searchProjectsList() {
      return this.projectList.filter((item) => {
        return this.searchParametars
          .toLowerCase()
          .split(" ")
          .every(
            (v) =>
              item.cipTitle.toLowerCase().includes(v) ||
              item.cipID.toString().includes(v) ||
              (item.projectName || "").toLowerCase().includes(v)
          );
      });
    },
  },
  methods: {
    handleTableChange(pagination) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
    },
    openCreateProjectModal() {
      this.createProjectModal = true;
      this.$nextTick(() => {
        this.$refs.projectName.focus();
      });
    },
    clickTableRow(record) {
      return {
        on: {
          click: (event) => {
            if (event === undefined || !event.target.closest(".js-delete")) {
              if (record.IsShelfCreated) {
                this.activeCipId = record.cipID;

                if (record.dataSource === "CSV" || record.dataSource === "") {
                  this.$router.push({
                    name: "shelf-builder",
                    params: {
                      id: record.surveyID,
                      projectName: record.projectName,
                      cipId: this.activeCipId,
                    },
                  });
                  this.activeCipId = null;
                } else {
                  this.getProcessStatus(record.surveyID, record.projectName);
                }
              } else {
                this.activeCipId = record.cipID;
                this.projectName = record.cipTitle;
                this.openCreateProjectModal();
              }
            } else {
              let _this = this;
              this.$confirm({
                title: "Delete project",
                class: "ant-modal--dark",
                content: () => (
                  <div>Are you sure you want to delete the project?</div>
                ),
                onOk() {
                  _this.deleteProject(record.surveyID);
                },
                okText: "Yes",
                onCancel() {},
              });
            }
          },
        },
      };
    },
    getProjects() {
      this.loading = true;
      let data = {
        wsName: "GetShelfbuilderStandaloneProjects",
        data: {},
      };
      const getShelfBuilderProjects = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getShelfBuilderProjects.then((data) => {
        this.projectList = data;
        const pagination = { ...this.pagination };
        pagination.total = this.projectList.length;
        pagination.hideOnSinglePage = true;
        this.pagination = pagination;
        this.loading = false;
      });
    },
    createProject() {
      if (
        this.projectName.trim() === "" ||
        this.createProjectLoading ||
        this.activeCipId === null
      ) {
        return;
      }
      let data = {
        wsName: "ShelfbuilderStandaloneProjects",
        data: {
          UserId: this.user.EncrypteduserID,
          projectName: this.projectName,
          cipId: this.activeCipId,
        },
      };
      this.createProjectLoading = true;
      const createShelfBuilderProjects = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      createShelfBuilderProjects.then((data) => {
        if (data.Id !== null) {
          this.createProjectLoading = false;
          this.createProjectModal = false;
          this.$router.push({
            name: "shelf-builder",
            params: {
              id: data.Id,
              projectName: this.projectName,
              cipId: this.activeCipId,
            },
          });
          this.projectName = "";
          this.activeCipId = null;
        } else {
          this.$error({
            title: "Unable to Create Project",
            onOk() {},
            content:
              "Please try again. If this continues, contact support for help.",
          });
        }
        this.createProjectLoading = false;
        this.createProjectModal = false;
        this.projectName = "";
        this.activeCipId = null;
      });
    },
    deleteProject(id) {
      this.deleteProjectLoading = true;
      let data = {
        wsName: "DeleteShelfbuilderStandaloneProjects",
        data: {
          SurveyId: id,
        },
      };
      const deleteShelfBuilderProjects = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      deleteShelfBuilderProjects.then(() => {
        this.deleteProjectLoading = false;
        this.getProjects();
      });
    },
    getProcessStatus(surveyID, projectName) {
      let form = new FormData();
      form.append("surveyId", surveyID);
      form.append("shelfType", "Control");

      let data = {
        wsName: "GetProcessStatus",
        data: {
          form,
        },
      };

      const getProcessStatus = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getProcessStatus.then((response) => {
        let _this = this;
        if (!response.ProcessFinished && response.ProcessStarted) {
          if (!response.EmailRequired) {
            this.$confirm({
              title: "Project information",
              class: "ant-modal--dark",
              content: () => (
                <div>
                  The creation process is still in progress. Would you like to
                  receive a notification via email once the project is ready?
                </div>
              ),
              onOk() {
                _this.sendEmailNotification(surveyID, projectName);
              },
              okText: "Yes",
            });
          } else {
            this.$notification["warning"]({
              message: `Upload in Progress`,
              description:
                "The creation process is still in progress. You will receive an email notification once the uploading process is completed.",
              placement: "bottomLeft",
              duration: 6,
            });
          }
        } else {
          this.$router.push({
            name: "shelf-builder",
            params: {
              id: surveyID,
              projectName: projectName,
              cipId: this.activeCipId,
            },
          });
          this.activeCipId = null;
        }
      });
    },
    sendEmailNotification(surveyId, projectName) {
      let form = new FormData();
      form.append("surveyId", surveyId);
      form.append("shelfType", "Control");
      form.append(
        "URL",
        `${window.location.origin}/${surveyId}/${encodeURIComponent(
          projectName
        )}/${this.activeCipId}`
      );

      let data = {
        wsName: "ProcessShelfdataEmailRequired",
        data: {
          form,
        },
      };
      const emailNotification = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      emailNotification.then((response) => {
        if (response.Success) {
          this.$notification["success"]({
            message: `Send Email on Completion`,
            description:
              "You will receive an email notification once the uploading process is completed.",
            placement: "bottomLeft",
            duration: 6,
          });
          this.$router.push({
            name: "shelf-builder-library",
          });
        } else {
          this.$notification["error"]({
            message: `Oops! Registration Error.`,
            description:
              "There was an issue registering for the email notification after completion of the upload process. Please try again. If it keeps happening, contact support for help.",
            placement: "bottomLeft",
            duration: 6,
          });
        }
      });
    },
  },
  watch: {
    searchProjectsList(newVal) {
      this.pagination.total = newVal.length;
    },
  },
  activated() {
    this.$refs.searchParametars.focus();
    this.user = jsUtils.getUserInfo();
    this.getProjects();
  },
};
</script>

<style lang="scss" scoped>
.shelf-builder-library {
  background: #1a1b1d;
  min-height: 100%;
  padding: 40px 20px;
  &__inner {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 70px;
    background: #1f2022;
    border-radius: 40px;
    h1 {
      text-align: left;
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 8px;
      margin-bottom: 26px;
      color: #fff;
    }
  }
  &__table-tools {
    justify-content: space-between;
    margin-bottom: 20px;
    .ant-btn {
      height: 36px;
      position: relative;
      border-radius: 50px;
      padding: 0 20px 0 16px;
    }
    .ant-input {
      width: 100%;
      height: 36px;
      border-radius: 50px;
      padding-left: 38px;
      &::placeholder {
        color: #6c6c6c;
        opacity: 1;
      }
    }
  }
  &__table-tools-search {
    max-width: 280px;
    position: relative;
    width: 100%;
    .fa-search {
      position: absolute;
      z-index: 999;
      left: 14px;
      color: #4a4c50;
      top: 10px;
    }
    &[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  ::v-deep .ant-table {
    .ant-table-thead {
      tr > th {
        &:nth-child(2) {
          white-space: nowrap;
        }
        &:nth-child(4) {
          text-align: center;
        }
      }
    }
    .ant-table-tbody {
      tr > td {
        &:nth-child(3),
        &:nth-child(6) {
          max-width: 300px;
        }
        &:last-child {
          width: 80px;
        }
      }
    }
  }
}

.shelf-created-status {
  text-align: center;
  display: block;
  .fa-check-circle {
    color: #01c101;
  }
  .fa-minus-circle {
    color: #6b6c6e;
  }
}
</style>
