var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',{staticClass:"dragArea",class:{
    empty: _vm.items.length === 0,
    startDragging: _vm.startDragging,
  },attrs:{"tag":"ul","list":_vm.items,"group":{ name: 'g1', pull: true, put: true },"handle":".js-draggable-handle"},on:{"change":_vm.onDragChange,"end":function($event){return _vm.$emit('end-dragging')},"start":function($event){return _vm.$emit('start-dragging')}}},_vm._l((_vm.items),function(item){return _c('li',{key:item.upc,staticClass:"draggable-item"},[_c('div',{staticClass:"draggable-item-content",class:{
        'is-active':
          _vm.selectedProduct && item.upc === _vm.selectedProduct.upc && !_vm.loading,
        'is-deep-dive': _vm.showInDeepDiveProducts && item.inDeepDive,
        'no-image': !item.thumbProductURL && !item.thumb_rotated_image_url,
        'hide-price': _vm.hidePrice,
      },style:({
        'padding-bottom': ((_vm.shelfPaddingBottom / _vm.sizeDivider) + "px"),
        'background-size': ("auto " + (_vm.shelfPaddingBottom / _vm.sizeDivider) + "px"),
        marginLeft: ((item.spaceLeft / _vm.sizeDivider) + "px"),
        marginRight: ((item.spaceRight / _vm.sizeDivider) + "px"),
      })},[(_vm.selectedProduct && _vm.selectedProduct.upc === item.upc)?_c('div',{staticClass:"shelf-builder__item-resize",style:({
          right: ((-4 / _vm.sizeDivider) + "px"),
          top: ((-11 / _vm.sizeDivider) + "px"),
          borderTopWidth: ((18 / _vm.sizeDivider) + "px"),
          borderBottomWidth: ((21 / _vm.sizeDivider) + "px"),
          borderLeftWidth: ((20 / _vm.sizeDivider) + "px"),
        }),on:{"mousedown":function($event){return _vm.$emit('init-resize', $event)}}}):_vm._e(),_c('div',{ref:("nestedProductRef_" + (item.upc)),refInFor:true,staticClass:"shelf-builder__item-image js-draggable-handle",attrs:{"data-upc":item.upc},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('item-clicked', item)}}},_vm._l((item.heightFacing),function(imgRow,imgRowIndex){return _c('div',{key:imgRowIndex,staticClass:"shelf-builder__item-image-row"},_vm._l((item.widthFacing),function(img,imgIndex){return _c('img',{directives:[{name:"show",rawName:"v-show",value:(item.thumbProductURL || item.thumb_rotated_image_url),expression:"item.thumbProductURL || item.thumb_rotated_image_url"}],key:imgIndex,attrs:{"src":((item.thumb_rotated_image_url || item.thumbProductURL) + "?time=" + _vm.timestamp),"width":item.productWidth / _vm.sizeDivider,"height":item.productHeight / _vm.sizeDivider,"crossOrigin":"anonymous"}})}),0)}),0),(item.showPrice && item.price !== 0 && item.price)?_c('div',{staticClass:"shelf-builder__item-price",style:({
          fontSize: ((13 / _vm.sizeDivider) + "px"),
          bottom: (((_vm.shelfPaddingBottom / 2 - 12) / _vm.sizeDivider) + "px"),
        })},[_vm._v(" "+_vm._s(_vm.currencySymbol || "")+_vm._s(item.price)+" ")]):_vm._e()])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }