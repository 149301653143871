<template>
  <div class="flex shelf-app">
    <div
      :style="{
        left: infoPosition.left + 'px',
        top: infoPosition.top + 'px',
      }"
      v-if="showMouseInfo && showMouseInfoCounter === 0"
      class="shelf-app__mouse-info"
    >
      <span v-if="mode === 'edit'"
        >Click any {{ activeBrand }} product to view and then select for
        inclusion in your study.</span
      >
      <span v-else>Click any product to view details.</span>
    </div>
    <div
      class="shelf-app__left"
      @mousemove="onMouseMove($event)"
      @mouseleave="showMouseInfo = false"
      @mouseenter="showMouseInfo = true"
    >
      <div class="shelf-app__left-image">
        <div
          v-show="pageLoader"
          class="flex items-center justify-center shelf-app__page-loader"
        >
          <i class="circle-loader"></i>Loading Shelf details...
        </div>
        <panZoom
          id="panzoom-element"
          ref="panzoom"
          @init="onInit"
          :class="{ enableScroll: enableScroll }"
        >
          <div class="zoomable">
            <img id="panzoom-img" :src="imageInfo.imageURL" />

            <div
              v-for="item in imageInfo.zonesobj"
              :key="item.zoneID"
              class="product-item"
              :class="{
                'is-active':
                  activeProduct !== null &&
                  item.areaID === activeProduct.areaID,
                'active-brand': item.activeBrand && mode === 'edit',
                'not-active-brand': !item.activeBrand && mode === 'edit',
                selected: item.selectedProduct && mode === 'edit',
              }"
              @click="productDetails(item.areaID)"
              :style="{
                left: item.areaCoord.split('[*]')[0] / initZoomStep + 'px',
                top: item.areaCoord.split('[*]')[1] / initZoomStep + 'px',
                width:
                  (item.areaCoord.split('[*]')[2] -
                    item.areaCoord.split('[*]')[0]) /
                    initZoomStep +
                  'px',
                height:
                  (item.areaCoord.split('[*]')[3] -
                    item.areaCoord.split('[*]')[1]) /
                    initZoomStep +
                  'px',
              }"
            >
              <div
                class="product-item__check"
                v-if="
                  item.activeBrand && mode === 'edit' && item.selectedProduct
                "
              >
                <div class="product-item__check-item"></div>
              </div>
              <div
                class="product-item__cta"
                v-if="mode === 'edit' && !dlbStatus"
              >
                <input
                  type="checkbox"
                  :value="item.areaID"
                  v-model="selectedEditableProducts"
                  :id="item.areaID"
                  :checked="item.selectedProduct"
                />
                <label :for="item.areaID">Select </label>
              </div>
            </div>
          </div>
        </panZoom>
      </div>
      <div class="flex items-center justify-center shelf-app__tools">
        <div class="flex items-center shelf-app__tools-zoom">
          <span>Zoom:</span>
          <div class="flex items-center shelf-app__tools-zoom-controls">
            <div
              class="flex items-center justify-center shelf-app__tools-zoom-cta"
              @click="zoom(-1)"
              :disabled="imageZoomPercent === 25"
            >
              <icon-base
                :width="16"
                :height="16"
                :viewBox1="24"
                :viewBox2="24"
                iconName="minus"
                iconColor="#D04395"
                iconStroke="transparent"
                ><icon-minus
              /></icon-base>
            </div>
            <span>{{ imageZoomPercent }}%</span>
            <div
              class="
                flex
                items-center
                justify-center
                shelf-app__tools-zoom-cta shelf-app__tools-zoom-cta--plus
              "
              @click="zoom(1)"
              :disabled="imageZoomPercent === 100"
            >
              <icon-base
                :width="16"
                :height="16"
                :viewBox1="24"
                :viewBox2="24"
                iconName="plus"
                iconColor="#D04395"
                iconStroke="transparent"
                ><icon-plus
              /></icon-base>
            </div>
          </div>
        </div>
        <div class="flex items-center shelf-app__tools-btn">
          <a-button
            type="primary"
            :disabled="disablePrevBtn"
            @click="changeActiveProduct('prev')"
            >Prev</a-button
          >
          <div class="shelf-app__tools-text">
            {{ activeProduct ? activeProduct.areaID : "/" }}
          </div>
          <a-button
            type="primary"
            @click="changeActiveProduct('next')"
            :disabled="disableNextBtn"
            >Next</a-button
          >
        </div>
      </div>
    </div>
    <div class="flex shelf-app__right" id="right-side">
      <div
        class="shelf-app__right-close"
        @click="$router.go(-1)"
        v-if="mode !== 'edit'"
      >
        <span>Close</span>
        <icon-base
          :width="30"
          :height="30"
          :viewBox1="1024"
          :viewBox2="1024"
          iconName="close"
          iconColor="#fff"
          iconStroke="transparent"
          ><icon-close
        /></icon-base>
      </div>
      <div
        v-if="activeProduct === null"
        class="
          flex
          items-center
          justify-center
          flex-1
          shelf-app__right-no-product
        "
      >
        <i class="circle-loader" v-if="loadProductDetails"></i>
        <div v-else>
          <a-icon type="info-circle" />
          <p v-if="mode === 'edit'">
            No product selected. Add products to your study by clicking then
            selecting.
          </p>
          <p v-else>
            No product selected. View details by clicking on the product image.
          </p>
        </div>
      </div>
      <div
        v-show="activeProduct !== null"
        class="flex-1 shelf-app__right-image"
      >
        <panZoom id="panzoom-details" :class="{ enableScroll: enableScroll }">
          <div class="zoomable">
            <img
              id="panzoom-details-img"
              :src="activeProduct ? activeProduct.popupURL : ''"
            />
          </div>
        </panZoom>
      </div>
      <div v-if="activeProduct !== null" class="shelf-app__right-info">
        <div class="flex justify-center shelf-app__right-zoom">
          <div
            class="flex items-center justify-center shelf-app__right-zoom-cta"
            @click="zoomDetailsImg(-1)"
            :disabled="currentDetailsZoomLevel === -1"
          >
            <icon-base
              :width="16"
              :height="16"
              :viewBox1="24"
              :viewBox2="24"
              iconName="minus"
              iconColor="#D04395"
              iconStroke="transparent"
              ><icon-minus
            /></icon-base>
          </div>
          <div
            class="
              flex
              items-center
              justify-center
              shelf-app__right-zoom-cta shelf-app__right-zoom-cta--plus
            "
            @click="zoomDetailsImg(1)"
            :disabled="currentDetailsZoomLevel === 1"
          >
            <icon-base
              :width="16"
              :height="16"
              :viewBox1="24"
              :viewBox2="24"
              iconName="plus"
              iconColor="#D04395"
              iconStroke="transparent"
              ><icon-plus
            /></icon-base>
          </div>
        </div>
        <span class="shelf-app__right-info-title"
          >{{ activeProduct ? activeProduct.brandName : "" }} -
          {{ activeProduct ? activeProduct.productName : "" }}</span
        >
        <span class="shelf-app__right-info-price"
          >Price:
          <i v-if="imageInfo.currencyPosition === 1">{{
            imageInfo.currencySymbol
          }}</i
          >{{ activeProduct ? activeProduct.price : ""
          }}<i v-if="imageInfo.currencyPosition !== 1">{{
            imageInfo.currencySymbol
          }}</i>
          ({{ activeProduct ? activeProduct.weight : "" }})</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import Panzoom from "panzoom";
import * as wsUtils from "@/utils/wsUtils.js";
import _ from "lodash";
import IconBase from "@/components/general/IconBase.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconMinus from "@/components/icons/IconMinus.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  name: "ShelfPreview",
  components: {
    IconBase,
    IconPlus,
    IconMinus,
    IconClose,
  },
  props: {
    shelfID: {
      type: [String, Number],
      require: true,
    },
    brandProductsList: {
      type: Array,
      default() {
        return [];
      },
    },
    mode: {
      type: String,
      default() {
        return "";
      },
    },
    activeBrand: {
      type: String,
      default() {
        return "";
      },
    },
    selectedProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      imageWidth: 1,
      imageCurrentWidth: 1,
      imageInfo: {
        imageURL: "",
        zonesobj: [],
      },
      activeProduct: null,
      loadProductDetails: false,
      initZoomStep: 1,
      pageLoader: true,
      panstartInit: false,
      currentZoomLevel: 1,
      imageZoomPercent: 50,
      currentDetailsZoomLevel: -1,
      enableScroll: false,
      infoPosition: {
        left: 0,
        top: 0,
      },
      showMouseInfo: false,
      showMouseInfoCounter: 0,
      shelf: [
        [
          {
            areaCoord: "135[*]194[*]282[*]421",
            imageURL:
              "https://s3.amazonaws.com/simage.hotspex.com/Projects/c1109/shelf-popups/0036000467789.png",
          },
          {
            areaCoord: "284[*]163[*]446[*]421",
            imageURL:
              "https://s3.amazonaws.com/simage.hotspex.com/Projects/c1109/shelf-popups/0036000413137.png",
          },
        ],
      ],
    };
  },
  computed: {
    selectedEditableProducts: {
      get: function () {
        return this.selectedProducts;
      },
      set: function (value) {
        this.$emit("selectedEditableProducts", value);
      },
    },
    imageWidthPercent() {
      return Number.parseFloat(
        (this.imageCurrentWidth / this.imageWidth) * 100
      ).toFixed(0);
    },
    disablePrevBtn() {
      let disableBtn = true;
      if (
        this.activeProduct !== null &&
        this.imageInfo.zonesobj.findIndex((obj) => {
          return obj.areaID === this.activeProduct.areaID;
        }) !== 0
      ) {
        disableBtn = false;
      }
      return disableBtn;
    },
    disableNextBtn() {
      let disableBtn = false;
      let productsLength;

      if (this.mode === "edit") {
        productsLength = this.imageInfo.zonesobj.filter((obj) => {
          return obj.activeBrand;
        }).length;
      } else {
        productsLength = this.imageInfo.zonesobj.length;
      }
      if (
        this.activeProduct !== null &&
        this.imageInfo.zonesobj.findIndex((obj) => {
          return obj.areaID === this.activeProduct.areaID;
        }) ===
          productsLength - 1
      ) {
        disableBtn = true;
      }
      return disableBtn;
    },
    dlbStatus() {
      return this.surveyType === "packtestextend";
    },
  },
  methods: {
    zoom(level) {
      let container = document.querySelector("#panzoom-img");
      let rect = container.getBoundingClientRect();
      let cx = rect.x + rect.width / 2;
      let cy = rect.y + rect.height / 2;

      const zoomLevels = [0.5, 1, 1.5, 2];

      let index = zoomLevels.findIndex((obj) => {
        return obj === this.currentZoomLevel;
      });
      console.log("zoomLevels[index]", zoomLevels[index]);
      if (level === -1) {
        index--;
        if (zoomLevels[index] !== undefined) {
          this.panzoom.zoomAbs(cx, cy, zoomLevels[index]);
          this.currentZoomLevel = zoomLevels[index];
          this.setImageZoomPercent(zoomLevels[index]);
        }
      } else {
        index++;
        if (zoomLevels[index] !== undefined) {
          this.panzoom.zoomAbs(cx, cy, zoomLevels[index]);
          this.currentZoomLevel = zoomLevels[index];
          this.setImageZoomPercent(zoomLevels[index]);
        }
      }

      setTimeout(() => {
        this.getImageWidth();
      }, 10);
    },
    setImageZoomPercent(zoomStep) {
      switch (zoomStep) {
        case 0.5:
          this.imageZoomPercent = 25;
          break;
        case 1:
          this.imageZoomPercent = 50;
          break;
        case 1.5:
          this.imageZoomPercent = 75;
          break;
        case 2:
          this.imageZoomPercent = 100;
          break;
        default:
          break;
      }
    },
    onInit(panzoomInstance) {
      panzoomInstance.on("panstart", () => {
        this.panstartInit = true;
      });
      panzoomInstance.on("panend", () => {
        setTimeout(() => {
          this.panstartInit = false;
        }, 200);
      });
      panzoomInstance.on("zoomend", () => {
        console.log("zoomend");
      });
    },
    getImageWidth() {
      this.imageCurrentWidth = document
        .getElementById("panzoom-img")
        .getBoundingClientRect().width;
    },
    productDetails(areaID) {
      this.showMouseInfo = false;
      this.showMouseInfoCounter = 1;
      if (!this.panstartInit) {
        this.loadProductDetails = true;
        let time = this.activeProduct === null ? 800 : 0;

        setTimeout(() => {
          this.activeProduct = this.imageInfo.zonesobj.find((obj) => {
            return obj.areaID === areaID;
          });
          this.loadProductDetails = false;
        }, time);
      }
    },
    changeActiveProduct(e) {
      let index;
      if (this.activeProduct !== null) {
        index = this.imageInfo.zonesobj.findIndex((obj) => {
          return obj.areaID === this.activeProduct.areaID;
        });
        if (e === "prev") {
          index--;
        } else {
          index++;
        }
      } else {
        index = 0;
      }

      this.activeProduct = this.imageInfo.zonesobj[index];
    },
    shelfDetails() {
      const GetShelfDetails = new Promise((resolve) => {
        wsUtils.GetShelfDetails(
          {
            shelfID: this.shelfID,
          },
          resolve
        );
      });

      GetShelfDetails.then((data) => {
        this.imageInfo = data;

        this.imageWidth = data.imageWidth;
        let _this = this;
        this.$nextTick(() => {
          this.panzoom = Panzoom(document.getElementById("panzoom-element"), {
            bounds: true,
            boundsPadding: 0.1,
            maxZoom: 3,
            minZoom: 0.1,
            transformOrigin: { x: 0.5, y: 0.5 },
            beforeWheel: function (e) {
              _this.enableScroll = true;
              var shouldIgnore = !e.altKey;
              return shouldIgnore;
            },
            beforeMouseDown: function () {
              _this.enableScroll = false;
            },
            // onTouch: function () {
            //   return false;
            // },
          });
        });
        this.getImageWidth();
        this.initZoomStep = this.imageWidth / this.imageCurrentWidth;
        this.setCertainBrandProducts();
        this.setSelectedProducts();
        let d = _.orderBy(this.imageInfo.zonesobj, ["activeBrand"]);

        this.imageInfo.zonesobj = d;
        setTimeout(() => {
          this.pageLoader = false;
        }, 1000);
      });
    },
    zoomDetailsImg(level) {
      let container = document.querySelector("#right-side");
      let rect = container.getBoundingClientRect();
      let cx = rect.width / 2;
      if (level === -1) {
        this.panzoomDetails.zoomTo(cx, 0, 0.5);
        this.currentDetailsZoomLevel = -1;
      } else {
        this.panzoomDetails.zoomTo(cx, 0, 2);
        this.currentDetailsZoomLevel = 1;
      }
    },
    resetData() {
      this.activeProduct = null;
      this.imageInfo = {
        imageURL: "",
        zonesobj: [],
      };
    },
    setCertainBrandProducts() {
      this.brandProductsList.forEach((brand) => {
        this.imageInfo.zonesobj.find(
          (product) => product.areaID === brand.areaID
        ).activeBrand = true;
      });
    },
    setSelectedProducts() {
      this.imageInfo.zonesobj.forEach((item) => {
        item.selectedProduct = false;
      });
      this.selectedProducts.forEach((item) => {
        this.imageInfo.zonesobj.find(
          (product) => product.areaID === item
        ).selectedProduct = true;
      });
    },
    onMouseMove(e) {
      this.infoPosition.left = e.pageX + 15;
      this.infoPosition.top = e.pageY + 15;
    },
  },
  activated() {},
  beforeRouteLeave(to, from, next) {
    this.resetData();
    next();
  },
  watch: {
    brandProductsList() {
      this.setCertainBrandProducts();
    },
    selectedProducts() {
      this.setSelectedProducts();
    },
  },
  mounted() {
    this.shelfDetails();
    let _this = this;
    this.panzoomDetails = Panzoom(document.getElementById("panzoom-details"), {
      bounds: true,
      boundsPadding: 0.1,
      maxZoom: 2,
      minZoom: 0.1,
      transformOrigin: { x: 0.5, y: 0.5 },
      autocenter: true,
      beforeWheel: function (e) {
        _this.enableScroll = true;
        var shouldIgnore = !e.altKey;
        return shouldIgnore;
      },
      beforeMouseDown: function () {
        _this.enableScroll = false;
      },
      onTouch: function () {
        return false;
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.shelf-app {
  background: #262626;
  height: 100vh;
  &__mouse-info {
    width: 170px;
    position: absolute;
    background: #fff;
    font-size: 12px;
    line-height: 15px;
    z-index: 1;
    pointer-events: none;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.05);
  }
  &__page-loader {
    color: #dcdcdc;
    position: absolute;
    inset: 0;
    z-index: 1;
    background: #2e2e2e;
  }
  &__left {
    flex: 1;
    border-right: 1px solid #2f2f2f;
    position: relative;
    .product-item {
      position: absolute;
      border: 2px solid transparent;
      cursor: pointer;
      &:hover {
        z-index: 1;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);
      }
      &.is-active {
        border-color: red;
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.3);
        z-index: 9999;
        .product-item__cta {
          display: flex;
          opacity: 1;
          visibility: visible;
          transform: translate(-50%, 0);
        }
      }
      &.not-active-brand {
        background: #2b2b2b;
        opacity: 0.85;
        pointer-events: none;
        .product-item__cta {
          display: none;
        }
      }
      &.selected {
        border-color: #29dbb7;
      }
      &.max-products:not(.selected) {
        .product-item__cta {
          cursor: default;
          input,
          label {
            opacity: 0.3;
            pointer-events: none;
          }
        }
      }
      &__check {
        position: absolute;
        left: 1px;
        top: 1px;
        width: 90%;
        max-width: 16px;
      }

      &__check-item {
        padding-bottom: 100%;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0ZM8 15l-5-5 1.4-1.4L8 12.2l7.6-7.6L17 6l-9 9Z' fill='%2329dbb7' fill-rule='evenodd'/%3E%3C/svg%3E")
          #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.25);
      }

      &__cta {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        background: white;
        bottom: -55px;
        border-radius: 3px;
        left: 50%;
        transform: translate(-50%, -4px);
        align-items: center;
        justify-content: center;
        padding: 6px 8px;
        transition: all 0.3s ease;
        box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.6);
        z-index: 1;
        &:after {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: -5px;
          content: "";
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #fff;
          transition: all 0.2s ease;
        }
        font-size: 14px;
        label {
          position: relative;
          font-size: 18px;
          font-weight: 500;
          padding-left: 30px;
          cursor: pointer;
          &:after,
          &:before {
            position: absolute;
            left: 0;
            top: 2px;
            width: 24px;
            height: 24px;
            content: "";
            border: 2px solid #29dbb7;
          }
          &:before {
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0ZM8 15l-5-5 1.4-1.4L8 12.2l7.6-7.6L17 6l-9 9Z' fill='%2329dbb7' fill-rule='evenodd'/%3E%3C/svg%3E")
              #fff;
            display: none;
            border-radius: 50%;
            z-index: 1;
            border: 0;
          }
        }
        input {
          margin-right: 4px;
          display: none;
          &:checked + label {
            &:before {
              display: block;
            }
            &:after {
              background: #29dbb7;
            }
          }
        }
      }
    }
  }
  &__left-image {
    height: 100%;
    overflow: hidden;
    position: relative;
    ::v-deep {
      .vue-pan-zoom-item.enableScroll {
        position: relative;
        &:after {
          position: absolute;
          inset: 0;
          content: "";
        }
      }
      .zoomable {
        transform: none !important;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__right {
    min-width: 25%;
    max-width: 25%;
    background: #2b2b2b;
    flex-direction: column;
    position: relative;
    box-shadow: -20px 0 40px 10px rgba(0, 0, 0, 0.1);
  }
  &__right-no-product {
    font-size: 14px;
    color: #dcdcdc;
    flex-direction: column;
    text-align: center;
    ::v-deep svg {
      width: 25px;
      height: 25px;
    }
    p {
      margin-top: 4px;
      padding: 0 20px;
    }
  }
  &__tools {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    width: 100%;
    padding: 8px 10px;
  }
  &__tools-zoom {
    position: absolute;
    right: 15px;
    color: #fff;
    padding: 5px 8px;
    background: rgba(38, 38, 38, 0.9);
    border-radius: 8px;
    > span {
      padding-right: 10px;
      font-size: 14px;
    }
  }
  &__tools-zoom-controls {
    span {
      padding: 0 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__tools-zoom-cta {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid var(--ctaRed);
    background: #fff;
    color: var(--ctaRed);
    cursor: pointer;
    transition: 0.3s ease all;
    &[disabled] {
      background: #d7d7d7;
      color: #fff;
      pointer-events: none;
    }
    &:hover {
      color: var(--ctaRedDark);
      border-color: var(--ctaRedDark);
    }
    svg {
      margin-bottom: 0;
    }
  }
  &__tools-btn {
    background: rgba(38, 38, 38, 0.9);
    padding: 5px;
    border-radius: 12px;
  }
  &__tools-text {
    padding: 0 15px;
    font-size: 14px;
    font-weight: 500;
    min-width: 78px;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
  }
  &__right-close {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    z-index: 1;
    span {
      padding-right: 5px;
      font-size: 12px;
    }
  }
  &__right-image {
    overflow: hidden;
    padding: 65px 0 11px;
    text-align: center;
    img {
      max-width: 90%;
    }
    ::v-deep {
      .vue-pan-zoom-item.enableScroll {
        position: relative;
        &:after {
          position: absolute;
          inset: 0;
          content: "";
        }
      }
      .zoomable {
        transform: none !important;
      }
    }
  }
  &__right-info {
    color: #fff;
    text-align: center;
    position: relative;
    padding: 15px 0 10px;
    background: rgba(38, 38, 38, 0.9);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.45);
    span {
      display: block;
      padding: 0 10px;
    }
  }
  &__right-zoom {
    gap: 10px;
    position: absolute;
    overflow: revert;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
  }
  &__right-zoom-cta {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid var(--ctaRed);
    background: #fff;
    color: var(--ctaRed);
    cursor: pointer;
    transition: 0.3s ease all;
    &[disabled] {
      background: #d7d7d7;
      color: #fff;
      pointer-events: none;
    }
    &:hover {
      color: var(--ctaRedDark);
      border-color: var(--ctaRedDark);
    }
    svg {
      margin-bottom: 0;
    }
  }
  &__right-info-price {
    font-size: 20px;
    margin-bottom: 5px;
    font-size: 14px;
    i {
      font-style: normal;
    }
  }
  &__right-info-title {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
  }
}
</style>
