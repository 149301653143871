<template>
  <div class="product-preview">
    <div
      class="product-preview__image"
      :class="{ loading: uploadProductImagesLoading }"
    >
      <a-upload-dragger
        name="file"
        accept="image/jpeg, image/png"
        :multiple="false"
        :showUploadList="false"
        :customRequest="uploadProductImage"
        ref="upload"
      >
        <div
          class="flex items-center justify-center product-preview__image-info"
        >
          <strong v-if="!uploadProductImagesLoading"
            >Drop image here or click to upload</strong
          >
          <div v-else class="flex items-center justify-center">
            <i class="circle-loader mr-5"></i>
            <strong>Uploading...</strong>
          </div>
        </div>

        <div class="product-preview__image-width">
          <i></i><span>{{ selectedProduct.productWidth }}px</span>
        </div>
        <div class="product-preview__image-height">
          <i></i><span>{{ selectedProduct.productHeight }}px</span>
        </div>
        <img
          ref="productPreviewImg"
          :style="imageStyle"
          :src="
            selectedProduct
              ? selectedProduct.rotated_image_url || selectedProduct.productURL
              : null
          "
          crossorigin="anonymous"
          v-if="
            (selectedProduct.rotated_image_url || selectedProduct.productURL) &&
            !loadingRotatedImage
          "
        />
        <Loader v-else></Loader>

        <svg
          v-if="
            !selectedProduct.rotated_image_url && !selectedProduct.productURL
          "
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="m14.265 15.938-1.718-1.703c-.789-.783-1.184-1.175-1.638-1.321a2 2 0 0 0-1.23 0c-.454.146-.849.538-1.638 1.32l-4 4.028m10.224-2.324.341-.339c.806-.8 1.209-1.199 1.671-1.345a2 2 0 0 1 1.247.015c.46.156.853.565 1.64 1.383L20 16.5m-5.735-.562 3.955 4.025M12 4H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C4 5.52 4 6.08 4 7.2v9.6c0 .647 0 1.107.042 1.462m0 0c.03.26.084.465.176.646a2 2 0 0 0 .874.874C5.52 20 6.08 20 7.2 20h9.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 18.48 20 17.92 20 16.8V12m-4-9 2.5 2.5m0 0L21 8m-2.5-2.5L21 3m-2.5 2.5L16 8"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a-upload-dragger>
      <div class="product-preview__image-tools">
        <div class="flex items-center gap-10 mb-0">
          <label> Width:</label>
          <a-input-number
            :min="1"
            :max="100000"
            v-model="productWidth"
            class="ant-input--dark"
            @change="$emit('calculate-new-positions')"
          />
        </div>
        <div class="flex items-center gap-10 mb-0">
          <label> Height:</label>
          <a-input-number
            :min="1"
            class="ant-input--dark"
            :max="100000"
            v-model="productHeight"
            @change="$emit('calculate-new-positions')"
          />
        </div>
        <div class="flex items-center gap-5">
          <div @click="rotateLeft" class="flex cursor-pointer">
            <icon-base
              :width="20"
              :height="20"
              :viewBox1="24"
              :viewBox2="24"
              iconName="rotate-left"
              iconColor="transparent"
              ><icon-rotate-left
            /></icon-base>
          </div>
          <div @click="rotateRight" class="flex cursor-pointer">
            <icon-base
              :width="20"
              :height="20"
              :viewBox1="24"
              :viewBox2="24"
              iconName="rotate-right"
              iconColor="transparent"
              ><icon-rotate-right
            /></icon-base>
          </div>
        </div>
        <div
          @click="$emit('calculate-natural-img-size', $refs.productPreviewImg)"
          class="flex items-center gap-5 cursor-pointer"
        >
          <font-awesome-icon icon="expand" class="font-size-16" />
          Optimize Image Ratio
        </div>
      </div>
    </div>
    <div class="product-preview__info">
      <span class="font-size-13">{{
        selectedProduct ? selectedProduct.brandName : "/"
      }}</span>
      <h2 v-if="!editName" @click="showEditNameField">
        {{ selectedProduct ? selectedProduct.productName : "/" }}
        <font-awesome-icon icon="edit" />
      </h2>
      <a-textarea
        ref="productName"
        class="ant-input--dark"
        v-model="productName"
        v-else
        @blur="editName = false"
      />
      <span class="font-size-15 product-preview__info-upc"
        >UPC:
        <strong>
          {{
            getUpcWithoutLast8Digits(
              selectedProduct ? selectedProduct.upc : "/"
            )
          }}</strong
        ></span
      >
      <div class="flex">
        <div class="font-size-14 mr-20 flex items-center gap-5">
          Price:
          <a-input-number
            :min="0"
            :max="1000000"
            class="ant-input--dark"
            v-model="productPrice"
          />
        </div>
        <div class="font-size-14 flex items-center gap-5">
          Weight:
          <a-input
            class="ant-input-small ant-input--dark"
            v-model="productWeight"
          />
        </div>
      </div>

      <div class="product-preview__info-cta">
        <div @click="$emit('delete-product-by-upc-confirm')">
          <font-awesome-icon icon="trash-alt" /> Delete product
        </div>
      </div>
      <div class="product-preview__info-footer">
        <a-button type="primary" :loading="loadingRotatedImage" @click="save"
          >Done</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
import IconBase from "@/components/general/IconBase.vue";
import IconRotateRight from "@/components/icons/IconRotateRight.vue";
import IconRotateLeft from "@/components/icons/IconRotateLeft.vue";
import Loader from "@/components/general/Loader.vue";

export default {
  components: {
    IconBase,
    IconRotateRight,
    IconRotateLeft,
    Loader,
  },
  name: "ProductPreview",
  props: {
    uploadProductImagesLoading: {
      type: Boolean,
    },
    selectedProduct: {
      type: Object,
    },
    surveyID: {
      type: String,
    },
    shelfType: {
      type: String,
    },
  },
  data() {
    return {
      editName: false,
      rotation: 0,
      swapDimensions: false,
      loadingRotatedImage: false,
    };
  },
  computed: {
    productWidth: {
      get() {
        return this.selectedProduct.productWidth;
      },
      set(newValue) {
        this.$emit("update-product-width", newValue);
      },
    },
    productHeight: {
      get() {
        return this.selectedProduct.productHeight;
      },
      set(newValue) {
        this.$emit("update-product-height", newValue);
      },
    },
    productName: {
      get() {
        return this.selectedProduct.productName;
      },
      set(newValue) {
        this.$emit("update-product-name", newValue);
      },
    },
    productPrice: {
      get() {
        return this.selectedProduct.price;
      },
      set(newValue) {
        this.$emit("update-product-price", newValue);
      },
    },
    productWeight: {
      get() {
        return this.selectedProduct.weight;
      },
      set(newValue) {
        this.$emit("update-product-weight", newValue);
      },
    },
    imageStyle() {
      let width, height;
      const productWidth = this.selectedProduct.productWidth;
      const productHeight = this.selectedProduct.productHeight;

      if (productWidth > 600) {
        width = 600;
        height = productHeight * (600 / productWidth);
      } else {
        width = productWidth;
        height = productHeight;
      }

      return {
        width: `${width}px`,
        height: `${height}px`,
        transform: `rotate(${this.rotation}deg)`,
      };
    },
  },
  watch: {},
  methods: {
    getUpcWithoutLast8Digits(upc) {
      return upc ? upc.slice(0, -8) : "/";
    },
    uploadProductImage(event) {
      this.$emit("upload-product-images", event, this.selectedProduct.upc);
    },
    showEditNameField() {
      this.editName = true;
      this.$nextTick(() => {
        this.$refs.productName.focus();
      });
    },
    rotateLeft() {
      this.rotation = (this.rotation - 90 + 360) % 360;
    },
    rotateRight() {
      this.rotation = (this.rotation + 90) % 360;
    },
    saveRotatedImage() {
      const img = this.$refs.productPreviewImg;
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      this.loadingRotatedImage = true;

      if (this.rotation % 180 !== 0) {
        canvas.width = img.naturalHeight;
        canvas.height = img.naturalWidth;
        this.swapDimensions = true;
      } else {
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        this.swapDimensions = false;
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((this.rotation * Math.PI) / 180);
      ctx.drawImage(img, -img.naturalWidth / 2, -img.naturalHeight / 2);

      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob);
        formData.append("surveyId", this.surveyID);
        formData.append("shelfType", this.shelfType);
        formData.append("upc", this.selectedProduct.upc.slice(0, -8));
        formData.append("swapDimensions", this.swapDimensions);

        const data = {
          wsName: "UploadRotatedProductImage",
          data: {
            form: formData,
          },
        };

        const saveImage = new Promise((resolve) => {
          wsUtils.ApiPost(data, resolve);
        });

        saveImage.then((data) => {
          if (data.success) {
            this.$notification["success"]({
              message: "Well done!",
              description: "Image rotated successfully.",
              placement: "bottomLeft",
              duration: 5,
            });
            this.$emit("update-product-width", data.width);
            this.$emit("update-product-height", data.height);
            this.$emit("update-product-image", {
              thumb: data.thumbImageURL,
              image: data.imageURL,
            });
          } else {
            this.$notification["error"]({
              message: "Error!",
              description:
                "Image rotate failed. Please try again or contact our support.",
              placement: "bottomLeft",
              duration: 5,
            });
          }

          setTimeout(() => {
            this.$emit("close-product-preview");
          }, 1000);
        });
      }, "image/png");
    },
    save() {
      if (this.rotation !== 0) {
        this.saveRotatedImage();
      } else {
        this.$emit("close-product-preview");
      }
    },
  },
  mounted() {
    this.rotation = 0;
    this.loadingRotatedImage = false;
  },
};
</script>

<style lang="scss" scoped>
.product-preview {
  display: flex;
  gap: 15px;
  &__image {
    max-width: 600px;
    width: 600px;
    height: 660px;
    position: relative;
    ::v-deep .ant-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 600px;
      border: none;
      background: #28292b;
      .ant-upload-btn {
        border: 0;
      }

      svg {
        width: 140px;
        path {
          stroke: #e3e3e3;
        }
      }

      &:hover {
        .product-preview__image-info {
          opacity: 1;
        }
      }
    }
    ::v-deep .ant-upload-drag-container {
      padding: 10px;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
    &.loading {
      .product-preview__image-info {
        opacity: 1;
      }
    }
  }
  &__image-width {
    position: absolute;
    top: -3px;
    left: 10px;
    right: 11px;
    color: #636468;
    i {
      width: 100%;
      position: absolute;
      left: 0;
      top: 10px;
      border-bottom: 1px dashed #636468;
    }
    span {
      background: #636468;
      position: relative;
      padding: 0 10px;
      font-size: 11px;
      display: block;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 60px;
      color: #fff;
      margin-left: -30px;
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      content: "<";
      z-index: 1;
    }
    &:before {
      position: absolute;
      right: -1px;
      top: 0px;
      content: ">";
      z-index: 1;
    }
  }
  &__image-height {
    position: absolute;
    right: -3px;
    top: 11px;
    bottom: 10px;
    width: 20px;
    color: #6c6c6c;
    i {
      height: 100%;
      position: absolute;
      top: 0;
      right: 10px;
      border-right: 1px dashed #c2c0c0;
    }
    span {
      background: #636468;
      color: #fff;
      position: relative;
      padding: 0 10px;
      font-size: 11px;
      transform: rotate(90deg);
      display: block;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      left: -21px;
      min-width: 60px;
    }
    &:after {
      position: absolute;
      left: 6px;
      top: -7px;
      content: "<";
      transform: rotate(90deg);
      z-index: 1;
    }
    &:before {
      position: absolute;
      right: 7px;
      bottom: -8px;
      content: ">";
      transform: rotate(90deg);
      z-index: 1;
    }
  }
  &__image-tools {
    width: 100%;
    display: flex;
    background: #28292b;
    color: #d1d1d1;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
    gap: 15px;
    justify-content: space-between;
    .large-field label {
      font-size: 13px;
      margin-bottom: 0;
      color: #d1d1d1;
    }
  }
  &__image-info {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    display: flex;
    border-radius: 5px;
    opacity: 0;
    transition: 0.3s ease opacity;
    width: 80%;
    z-index: 1;
  }
  &__info {
    margin-top: -4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #d1d1d1;
    h2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 15px;
      color: #fff;
      font-weight: 400;
      padding: 4px 0;
      svg {
        margin-left: 6px;
        color: #3fa9ff;
        font-size: 14px;
      }
    }
    textarea.ant-input {
      font-size: 18px;
      font-weight: 400;
      border: 1px solid #434344;
      line-height: normal;
      padding: 4px 6px;
      margin: 0 -6px 15px;
    }
  }

  &__info-upc {
    margin-bottom: 45px;
  }

  &__info-cta {
    border-top: 1px dashed #353434;
    padding-top: 15px;
    margin-top: 15px;
    > div {
      cursor: pointer;
      display: inline-flex;
      gap: 5px;
      align-items: center;
      svg {
        margin-top: -2px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__info-footer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    align-items: flex-end;
  }
  ::v-deep .ant-input-number {
    padding: 0;
    border-color: #434344;
    .ant-input-number-handler-wrap {
      background: #434344;
      border-left: 1px solid #404144;
      opacity: 1;
      .ant-input-number-handler-up-inner,
      .ant-input-number-handler-down-inner {
        color: #fff;
      }
      .ant-input-number-handler-down {
        border-top: 1px solid #5a5b5f;
      }
    }
  }
  ::v-deep .ant-input-small {
    width: 100%;
    max-width: 88px;
    padding: 0 11px;
  }
}
</style>
