<template>
  <div class="upload">
    <div class="upload__close" @click="$emit('close')"></div>
    <div class="upload__overlay"></div>
    <div class="upload__inner position-r">
      <h2>Update</h2>

      <ul class="upload__tabs">
        <li
          @click="activeTab = 'shelf'"
          :class="{ active: activeTab === 'shelf' }"
        >
          Shelf Images
        </li>

        <a-tooltip placement="right">
          <template slot="title" v-if="inDeepDiveProducts.length === 0">
            Please select one or more DeepDive products to proceed
          </template>
          <li
            v-if="!hideProductsTab"
            :disabled="inDeepDiveProducts.length === 0"
            @click="
              inDeepDiveProducts.length !== 0
                ? ((activeTab = 'products'), matchAndFillDeepDives())
                : (activeTab = 'shelf')
            "
            :class="{ active: activeTab === 'products' }"
          >
            Product Images
          </li>
        </a-tooltip>
      </ul>

      <div class="upload__inner-scroll">
        <div class="projects flex" v-if="activeTab === 'shelf'">
          <div class="projects__images">
            <h3 class="projects__images-title">Shelf Images</h3>
            <div
              class="projects__images-wrapper"
              v-for="(shelf, index) in allShelves"
              :key="'shelfImg' + shelf.shelfType"
            >
              <h3>{{ shelf.shelfType }}</h3>
              <div
                class="flex"
                :style="{
                  height:
                    gcsProjects[index] && gcsProjects[index].ShelfImageList
                      ? gcsProjects[index].ShelfImageList.length * 50 +
                        (gcsProjects[index].ShelfImageList.length - 1) * 5 +
                        'px'
                      : '50px',
                }"
              >
                <div class="projects__images-item">
                  <img :src="shelf.shelfImageURLUnpricedThumb" alt=" " />
                </div>
              </div>
            </div>
          </div>

          <div class="projects__urls">
            <h3 class="projects__images-title">Image Links From CIP</h3>
            <div
              v-for="(project, index) in gcsProjects"
              :key="index"
              class="projects__urls-wrapper"
            >
              <div class="flex justify-between items-center">
                <h3>{{ project.ShelfName }}</h3>
              </div>

              <draggable
                :list="project.ShelfImageList"
                handle=".js-draggable-handle"
                group="shelfImages"
                :tag="'div'"
                item-key="ShelfImageName"
                class="projects__urls-item"
                @start="onStart"
                @end="onEnd"
                :move="onDragMove"
              >
                <div
                  v-for="(item, itemIndex) in project.ShelfImageList"
                  :key="'item-' + itemIndex"
                  class="js-draggable-handle projects__urls-item-inner"
                >
                  <div class="icon-draggable-handle">
                    <i v-for="item in 8" :key="item"></i>
                  </div>
                  <font-awesome-icon icon="check-circle" />

                  <div>
                    <p v-for="url in item.ShelfImageURL" :key="url">
                      {{ url }}
                    </p>
                  </div>
                  <h4 v-if="item.ShelfImageName">{{ item.ShelfImageName }}</h4>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div
          class="projects projects--products flex"
          v-if="activeTab === 'products'"
        >
          <div class="projects__images">
            <h3 class="projects__images-title">Product Images</h3>
            <div
              class="projects__images-wrapper"
              v-for="(group, shelfType, index) in products"
              :key="'product-' + shelfType"
            >
              <h3>{{ shelfType }}</h3>
              <div
                :style="`min-height: ${
                  gcsProjects[index] &&
                  gcsProjects[index].DeepDive &&
                  gcsProjects[index].DeepDive.length > 0
                    ? gcsProjects[index].DeepDive.length * 50 +
                      (gcsProjects[index].DeepDive.length - 1) * 5
                    : 50
                }px`"
              >
                <div
                  class="projects__images-item"
                  v-for="product in group"
                  :key="product.upc"
                >
                  <img
                    :src="product.rotated_image_url || product.productURL"
                    :alt="product.upc"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="projects__urls">
            <h3 class="projects__images-title">Image Links From CIP</h3>
            <div
              v-for="(project, index) in gcsProjects"
              :key="index"
              class="projects__urls-wrapper"
            >
              <h3>{{ project.ShelfName }}</h3>
              <draggable
                :list="project.DeepDive"
                handle=".js-product-draggable-handle"
                group="shelfProducts"
                :tag="'div'"
                item-key="DeepDiveName"
                class="projects__urls-item"
                :class="{ empty: project.DeepDive.length === 0 }"
                :force-fallback="true"
                @end="onProductEnd"
                :style="`min-height: ${
                  products[project.TempShelfName]
                    ? products[project.TempShelfName].length * 50 +
                      (products[project.TempShelfName].length - 1) * 5
                    : '50px'
                }px;`"
              >
                <div
                  v-for="(item, itemIndex) in project.DeepDive"
                  :key="'product-' + itemIndex"
                  class="js-product-draggable-handle projects__urls-item-inner"
                  :class="{
                    empty: !item.DeepDiveImageURL && !item.DeepDiveName,
                  }"
                >
                  <div class="icon-draggable-handle">
                    <i v-for="item in 8" :key="item"></i>
                  </div>

                  <p v-if="item.DeepDiveImageURL">
                    {{ item.DeepDiveImageURL }}
                  </p>

                  <h4 v-if="item.DeepDiveName">{{ item.DeepDiveName }}</h4>
                </div>
              </draggable>
            </div>
          </div>
        </div>
      </div>

      <div class="upload__controls flex justify-end">
        <a-button type="dark" class="b-radius-40" @click="$emit('close')">
          Cancel
        </a-button>
        <a-button
          type="primary"
          class="b-radius-40"
          @click="saveShelfRenderDetails"
        >
          <Loader class="space-0" text="" v-if="loading"></Loader>
          <span v-else><a-icon type="save" /> Save</span>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import cloneDeep from "lodash/cloneDeep";
import * as wsUtils from "@/utils/wsUtils.js";
import Loader from "@/components/general/Loader.vue";

export default {
  name: "UploadModal",
  components: {
    draggable,
    Loader,
  },
  props: {
    inDeepDiveProducts: {
      type: Array,
      default() {
        return [];
      },
    },
    allShelves: {
      type: Array,
      default() {
        return [];
      },
    },
    gcsProjects: {
      type: Array,
      default() {
        return [{ ShelfImageList: [] }];
      },
    },
    surveyID: {
      type: String,
    },
    userId: {
      type: String,
    },
    hideProductsTab: {
      type: Boolean,
    },
    allShelvesData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      products: [],
      draggedElement: null,
      replacedElement: null,
      activeTab: "shelf",
      updatedAllShelves: [],
      updatedData: null,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    onStart(event) {
      this.draggedElement = event.from.__vue__.realList[event.oldIndex];
    },

    onEnd(event) {
      document
        .querySelectorAll(".js-draggable-handle.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });
      if (event.from !== event.to && this.draggedElement) {
        const targetIndex = event.newIndex;
        const targetList = event.to.__vue__.list;
        const sourceList = event.from.__vue__.list;
        const sourceIndex = event.oldIndex;

        if (targetIndex < targetList.length) {
          let replacedElIndex;
          if (targetIndex + 1 < targetList.length) {
            replacedElIndex = targetIndex + 1;
          } else if (targetIndex - 1 >= 0) {
            replacedElIndex = targetIndex - 1;
          } else {
            replacedElIndex = targetIndex + 1;
          }

          const replacedElement = targetList[replacedElIndex];

          targetList.splice(targetIndex, 1, this.draggedElement);

          const replacedElementIndex = targetList.findIndex(
            (el) => el === replacedElement
          );

          if (replacedElementIndex !== -1) {
            targetList.splice(replacedElementIndex, 1);
          }

          if (sourceIndex > -1) {
            sourceList.splice(sourceIndex, 0, replacedElement);
          }
        }

        this.draggedElement = null;

        this.$forceUpdate();
      }
    },
    onDragMove() {
      document
        .querySelectorAll(".js-draggable-handle.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });

      const chosenElement = document.querySelector(
        ".js-draggable-handle.sortable-chosen"
      );
      if (chosenElement) {
        const prevElement =
          chosenElement.previousElementSibling &&
          chosenElement.previousElementSibling.classList.contains(
            "js-draggable-handle"
          )
            ? chosenElement.previousElementSibling
            : null;
        const nextElement =
          chosenElement.nextElementSibling &&
          chosenElement.nextElementSibling.classList.contains(
            "js-draggable-handle"
          )
            ? chosenElement.nextElementSibling
            : null;

        const chosenRect = chosenElement.getBoundingClientRect();
        const chosenMidY = chosenRect.top + chosenRect.height / 2;

        if (event.clientY >= chosenMidY) {
          if (nextElement) {
            nextElement.classList.add("is-active");
          }
        } else {
          if (prevElement) {
            prevElement.classList.add("is-active");
          }
        }
      }
    },
    onProductEnd(event) {
      const sourceIndex = event.oldIndex;
      this.matchAndFillDeepDives(sourceIndex);
    },
    updateActiveClass() {
      document
        .querySelectorAll(".js-draggable-handle.is-active")
        .forEach((el) => {
          el.classList.remove("is-active");
        });

      const chosenElement = document.querySelector(
        ".js-draggable-handle.sortable-chosen"
      );
      if (chosenElement) {
        let activeElement = chosenElement.nextElementSibling;

        if (
          !activeElement ||
          !activeElement.classList.contains("js-draggable-handle")
        ) {
          activeElement = chosenElement.previousElementSibling;
        }

        if (
          activeElement &&
          activeElement.classList.contains("js-draggable-handle")
        ) {
          activeElement.classList.add("is-active");
        }
      }
    },
    /**
     * Match and fill missing DeepDive objects in gcsProjects based on the count of inDeepDiveProducts
     */
    matchAndFillDeepDives(sourceIndex) {
      // Iterate through each product
      this.gcsProjects.forEach((product) => {
        if (!product.DeepDive) {
          return; // Continue to the next iteration
        }
        // Count the occurrences of the current shelfType in inDeepDiveProducts
        const countCurrent = this.inDeepDiveProducts.filter(
          (c) => c.shelfType === product.TempShelfName
        ).length;

        // If the count of occurrences is greater than the length of DeepDive array in the product
        if (countCurrent > product.DeepDive.length) {
          const missingCount = countCurrent - product.DeepDive.length;
          for (let i = 0; i < missingCount; i++) {
            const emptyObject = {
              DeepDiveName: "",
              DeepDiveImageName: "",
              DeepDiveImageURL: "",
            };

            if (
              sourceIndex !== undefined &&
              sourceIndex >= 0 &&
              sourceIndex < product.DeepDive.length
            ) {
              product.DeepDive.splice(sourceIndex, 0, emptyObject);
            } else {
              product.DeepDive.push(emptyObject);
            }
          }
        } else if (countCurrent < product.DeepDive.length) {
          // Add this condition to check before removing
          // Look for the last empty object to remove if the countCurrent is less than the DeepDive length
          for (let i = product.DeepDive.length - 1; i >= 0; i--) {
            if (
              product.DeepDive[i].DeepDiveName === "" &&
              product.DeepDive[i].DeepDiveImageName === "" &&
              product.DeepDive[i].DeepDiveImageURL === ""
            ) {
              product.DeepDive.splice(i, 1);
              break; // Ensure to break after removing one empty object
            }
          }
        }
      });
    },
    adjustGcsShelves() {
      const initialLength = this.gcsProjects.length;
      const difference = this.allShelves.length - initialLength;

      if (difference > 0) {
        for (let i = 0; i < difference; i++) {
          const newProject = {
            TempShelfName: `Test ${initialLength + i}`,
            ShelfName: `Test ${initialLength + i}`,
            DeepDive: [],
            ShelfImageList: [
              {
                ShelfImageName: null,
                ShelfImageURL: [""],
              },
            ],
          };

          this.gcsProjects.push(newProject);
        }
      }
    },
    groupedProductsByShelfType() {
      this.products = this.inDeepDiveProducts.reduce((acc, product) => {
        if (!acc[product.shelfType]) {
          acc[product.shelfType] = [];
        }
        acc[product.shelfType].push(product);
        return acc;
      }, {});
    },
    /**
     * Update all shelves with GCS data.
     *
     * This function updates all shelves with GCS data by mapping through each shelf and integrating GCS data if available.
     */
    updateAllShelvesWithGCS() {
      // Map through each shelf and integrate GCS data if available
      this.updatedAllShelves = this.allShelves.map((shelf, index) => {
        // Check if GCS data is available for the current index
        if (index < this.gcsProjects.length && this.gcsProjects[index]) {
          // Get the GCS project for the current index
          const gcsProject = this.gcsProjects[index];
          // Get the first GCS shelf image for the GCS project
          const gcsShelf = gcsProject.ShelfImageList[0];
          // Deep clone the GCS shelf products for the GCS project
          const gcsShelfProducts = cloneDeep(gcsProject.DeepDive);
          // Return the updated shelf with integrated GCS data
          return {
            ...shelf,
            gcsShelf,
            gcsShelfProducts,
            gcsTempShelfName: gcsProject.TempShelfName,
            gcsShelfName: gcsProject.ShelfName,
          };
        }
        // Return the original shelf if no GCS data is available for the current index
        return shelf;
      });
    },
    /**
     * Maps the products to the shelves based on their types
     */
    mapProductsToShelves() {
      // Iterate through each updated shelf
      this.updatedAllShelves.forEach((shelf) => {
        const productArray = this.products[shelf.shelfType];

        // Check if the product array and shelf products exist
        if (productArray && shelf.gcsShelfProducts) {
          // Iterate through each product on the shelf
          shelf.gcsShelfProducts.forEach((product, productIndex) => {
            // Check if the product index is within the product array bounds
            if (productArray.length > productIndex) {
              // Update the shelf product with the corresponding product information
              this.$set(shelf.gcsShelfProducts, productIndex, {
                ...product,
                ...productArray[productIndex],
              });
            }
          });
        }
      });
    },
    processShelves() {
      this.updateAllShelvesWithGCS();
      this.mapProductsToShelves();

      let shelfMap = new Map();

      this.updatedAllShelves.forEach((shelf) => {
        const {
          shelfWidth,
          shelfHeight,
          shelfImageURLUnpriced,
          shelfImageURL,
        } = shelf;
        const { ShelfName, ShelfImageURL } = shelf.gcsShelf;

        if (!ShelfName) return;

        if (!shelfMap.has(ShelfName)) {
          const shelfData = shelfMap.get(ShelfName) || {
            ShelfType: ShelfName,
            ShelfWidth: shelfWidth,
            ShelfHeight: shelfHeight,
            ShelfURL: [
              {
                ShelfImageURL: shelfImageURLUnpriced,
                GcsImageURL: ShelfImageURL ? ShelfImageURL[0] : "",
              },
              {
                ShelfImageURL: shelfImageURL,
                GcsImageURL: ShelfImageURL ? ShelfImageURL[1] : "",
              },
            ],
            ProductURL: [],
          };

          shelfMap.set(ShelfName, shelfData);
        } else {
          const existingShelf = shelfMap.get(ShelfName);
          existingShelf.ShelfURL.push(
            {
              ShelfImageURL: shelfImageURLUnpriced,
              GcsImageURL: ShelfImageURL[0],
            },
            {
              ShelfImageURL: shelfImageURL,
              GcsImageURL: ShelfImageURL ? ShelfImageURL[1] : "",
            }
          );
        }
      });

      this.updatedAllShelves.forEach((shelf) => {
        shelf.gcsShelfProducts.forEach((product) => {
          const productShelfName = product.gcsShelfName;

          if (!productShelfName) return;

          if (product.productURL && product.DeepDiveImageURL) {
            const shelfData = shelfMap.get(productShelfName) || {
              ShelfType: productShelfName,
              ShelfWidth: "",
              ShelfHeight: "",
              ShelfURL: [],
              ProductURL: [],
            };

            shelfData.ProductURL.push({
              UPC: product.upc,
              Width: product.width,
              Height: product.height,
              ShelfImageURL: product.rotated_image_url || product.productURL,
              GcsImageURL: product.DeepDiveImageURL,
            });

            shelfMap.set(productShelfName, shelfData);
          }
        });
      });

      this.updatedData = {
        Shelfs: Array.from(shelfMap.values()),
        SurveyID: this.surveyID,
      };
    },

    gcsImageUpload() {
      this.processShelves();

      let data = {
        wsName: "GCSImageUpload",
        data: this.updatedData,
      };
      const getGCSProjects = new Promise((resolve) => {
        wsUtils.ShelfBuilderPost(data, resolve);
      });

      getGCSProjects.then((data) => {
        if (data.Success) {
          this.$notification["success"]({
            message: "Update Successful",
            description: "Your data has been successfully updated",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          this.$notification["error"]({
            message: "Update Failed",
            description:
              "An error has occurred. Please try again or contact support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
        this.loading = false;
      });
    },

    saveShelfRenderDetails() {
      this.loading = true;
      const updatedData = this.allShelvesData.map((shelf) => {
        return {
          ...shelf,
          bays: shelf.bays.map((bay) => {
            return {
              ...bay,
              shelves: bay.shelves.map((shelf) => {
                return {
                  ...shelf,
                  products: shelf.products.map((product) => {
                    // Remove the last 8 digits from the UPC of the product
                    const updatedProduct = {
                      ...product,
                      upc: product.upc.slice(0, -8),
                    };

                    // Check if the product has a 'stack' array and remove the last 8 digits from each stacked product's UPC
                    if (updatedProduct.stack) {
                      updatedProduct.stack = updatedProduct.stack.map(
                        (stackedProduct) => {
                          return {
                            ...stackedProduct,
                            upc: stackedProduct.upc.slice(0, -8),
                            parentUPC: stackedProduct.parentUPC.slice(0, -8),
                          };
                        }
                      );
                    }

                    return updatedProduct;
                  }),
                };
              }),
            };
          }),
        };
      });
      const shelfData = {
        SurveyId: this.surveyID,
        UserId: this.userId,
        shelf: [...updatedData],
      };

      const saveShelfRenderDetails = new Promise((resolve) => {
        wsUtils.SaveShelfRenderDetails(shelfData, resolve);
      });

      saveShelfRenderDetails.then((data) => {
        if (data.Success) {
          this.gcsImageUpload();
        } else {
          this.$notification["error"]({
            message: "Update Failed",
            description:
              "An error has occurred. Please try again or contact support.",
            placement: "bottomLeft",
            duration: 5,
          });
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.matchAndFillDeepDives();
    this.groupedProductsByShelfType();
    this.updateAllShelvesWithGCS();
    this.mapProductsToShelves();
    this.adjustGcsShelves();
  },
};
</script>

<style lang="scss" scoped>
.upload {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__tabs {
    display: flex;
    color: #fff;
    padding: 0 0 16px;
    margin: 0;
    flex: 1;
    gap: 10px;
    position: relative;
    &:after {
      position: absolute;
      left: -20px;
      right: -20px;
      border-bottom: 1px solid #2c2d30;
      content: "";
      bottom: 0;
    }

    li {
      padding: 0px 15px;
      height: 32px;
      border: 1px solid #4c4d4e;
      background: #3c3d3e;
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      display: flex;
      align-items: center;
      border-radius: 40px;
      &.active,
      &:hover {
        background: #3f4042;
        border-color: #1990ff;
      }
      &[disabled] {
        opacity: 0.5;
        cursor: default;
        &:hover {
          background: #3c3d3e;
          border-color: #4c4d4e;
        }
      }
    }
  }
  &__close {
    position: absolute;
    top: 25px;
    right: -12px;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #555555;
      transition: background-color 0.3s ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }

  &__overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.96);
  }
  &__inner {
    max-width: 80%;
    background: #1a1b1d;
    border-radius: 6px;
    z-index: 1;
    width: 100%;
    position: relative;
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.14);
    padding: 75px 15px 8px;
    height: 90%;
    display: flex;
    flex-direction: column;
    margin: 0 80px;
    overflow: hidden;
    h2 {
      font-size: 18px;
      color: #fff;
      position: absolute;
      background: #1a1b1d;
      width: 100%;
      left: 0;
      top: 0;
      padding: 16px 15px;
      border-bottom: 1px solid #2c2d30;
    }
  }

  &__inner-scroll {
    height: 100%;
    overflow: auto;
    color: #fff;
    padding: 24px 0 0;
  }

  &__controls {
    border-top: 1px solid #4c4d4e;
    margin: 0 -15px 0;
    padding: 10px 15px 2px 10px;
    gap: 10px;
    .ant-btn {
      height: 36px;
      min-width: 90px;
    }
  }
}

.projects {
  &--products {
    .projects__images-wrapper {
      padding-bottom: 0;
      margin-bottom: 19px;
    }
    .projects__images-item {
      height: 50px;
      margin-bottom: 5px;
      flex: none;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__images {
    width: 140px;
  }
  &__images-title {
    color: #fff;
    font-size: 16px;
    margin-bottom: 15px;
  }
  &__images-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 19px;
    h3 {
      font-size: 14px;
      color: #e0e0e0;
      font-weight: 400;
    }
  }
  &__images-item {
    flex: 1;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    justify-content: center;
    border: 1px solid #3a3c3e;
    border-radius: 8px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__shelf-size {
    gap: 20px;
    margin-top: -12px;
  }
  &__shelf-size-item {
    display: flex;
    gap: 6px;
    align-items: flex-end;
    label {
      font-size: 11px;
      display: block;
      color: #7e7e7e;
      line-height: normal;
    }
    input {
      background: transparent;
      border: 0;
      border-bottom: 1px solid #3a3c3e;
      border-radius: 0;
      width: 65px;
      color: #e0e0e0;
      padding: 0;
      height: 17px !important;
      font-size: 13px;
      outline: none;
      &:focus {
        border-color: #5a5a5a;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      -moz-appearance: textfield;
    }
  }
  &__urls {
    flex: 1;
    margin-left: 12px;
  }
  &__urls-wrapper {
    margin-bottom: 25px;
    h3 {
      font-size: 14px;
      color: #e0e0e0;
      font-weight: 400;
      min-height: 21px;
    }
  }
  &__urls-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &.empty {
      background: #1f2021;
      border-radius: 12px;
      min-height: 50px;
    }
  }
  &__urls-item-inner {
    padding: 6px 10px;
    background: #232427;
    position: relative;
    display: flex;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    cursor: grab;
    transition: background 0.3s ease;
    min-height: 50px;
    &:last-child {
      margin-bottom: 0;
    }
    &.sortable-chosen {
      cursor: grabbing;
    }
    &.empty {
      background: transparent;
      border: 1px solid #3a3c3e;
    }

    .fa-check-circle {
      color: #36373b;
    }
    &:first-child {
      .fa-check-circle {
        color: #40a9ff;
      }
    }
    &:hover {
      background: #292a2d;
      .icon-draggable-handle i {
        background-color: #5d5d5e;
      }
      h4 {
        background: #40a9ff;
        color: #fff;
      }
    }
    h4 {
      margin: 0;
      background: #3a3c3e;
      color: #cacaca;
      display: inline-flex;
      font-size: 13px;
      align-items: center;
      padding: 10px 18px;
      border-radius: 40px;
      transition: all 0.3s ease;
      line-height: normal;
      white-space: nowrap;
      transition: background 0.3s ease;
      margin-left: auto;
      min-width: 95px;
      justify-content: center;
    }
  }
  p {
    font-size: 12px;
    margin-bottom: 2px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.icon-draggable-handle {
  column-gap: 3px;
  row-gap: 4px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  i {
    width: 4px;
    height: 4px;
    background-color: #3a3c3e;
    border-radius: 50%;
    transition: background 0.3s ease;
  }
}

.js-draggable-handle {
  &.is-active {
    background: rgb(106, 106, 106);
    opacity: 0.3;
  }
  &.sortable-ghost {
    opacity: 0.1;
  }
}

.drag-area {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.image-container {
  border: 1px solid blue;
  padding: 15px;
}
</style>
