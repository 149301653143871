var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upload"},[_c('router-link',{staticClass:"upload__back",attrs:{"to":"/shelf-builder-library"}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" Projects list")],1),(_vm.activeUploadForm === null && !_vm.showMissingUPC)?_c('div',{staticClass:"upload__cta"},[_c('h1',[_vm._v("Ready to move with Shelf Builder?")]),_c('p',[_vm._v("To start, simply upload a CSV file or a PSA file.")]),_c('div',[_c('a-button',{staticClass:"b-radius-40",attrs:{"type":"primary"},on:{"click":function($event){_vm.activeUploadForm = 'csv'}}},[_vm._v("Upload CSV ")]),_vm._m(0),_c('a-button',{staticClass:"b-radius-40",attrs:{"type":"primary"},on:{"click":function($event){_vm.activeUploadForm = 'psa'}}},[_vm._v("Upload PSA ")])],1)]):_vm._e(),(_vm.activeUploadForm === 'csv' && !_vm.showMissingUPC)?_c('div',{staticClass:"upload__inner"},[_c('h2',[_vm._v("Upload CSV")]),_c('a-upload-dragger',{ref:"upload",attrs:{"name":"file","accept":".csv, .zip","multiple":false,"showUploadList":false,"customRequest":_vm.handleCSVUpload}},[_c('div',[_c('div',{staticClass:"upload__cta-close",on:{"click":_vm.closeUploadForm}}),_c('font-awesome-icon',{attrs:{"icon":"file-upload"}}),_c('p',[_vm._v("Drag and drop CSV file or browse file on your computer.")])],1)]),_c('div',{staticClass:"upload-item"},[_c('div',{staticClass:"upload-item__circle"},[_c('span',{staticClass:"upload-item__circle-label",class:{
            'upload-item__circle-label--enabled': _vm.uploadedFile === 'csv',
          }},[_vm._v("CSV")]),_c('font-awesome-icon',{attrs:{"icon":"file"}})],1),_c('p',[_vm._v(_vm._s(_vm.statusMessage))]),(_vm.uploadingFile === 'csv')?_c('Loader',{staticClass:"space-0",attrs:{"text":""}}):(_vm.uploadedFile === 'csv')?_c('font-awesome-icon',{attrs:{"icon":"check-circle"}}):_c('font-awesome-icon',{attrs:{"icon":"minus-circle"}})],1)],1):_vm._e(),(
      (_vm.activeUploadForm === 'psa' || _vm.activeUploadForm === 'zip') &&
      !_vm.showMissingUPC
    )?_c('div',{staticClass:"upload__inner"},[_c('h2',[_vm._v("Upload "+_vm._s(_vm.psaOrZipLabel))]),_c('a-upload-dragger',{ref:"upload",attrs:{"name":"file","accept":".zip","multiple":false,"showUploadList":false,"customRequest":_vm.psaZipUpload,"disabled":_vm.uploadingFile !== '' || _vm.processShelfdataStarted}},[_c('div',[(!_vm.showEmailBtn)?_c('div',{staticClass:"upload__cta-close",on:{"click":_vm.closeUploadForm}}):_vm._e(),_c('font-awesome-icon',{attrs:{"icon":"file-upload"}}),(_vm.psaOrZipLabel === 'PSA')?_c('p',[_vm._v(" To begin, simply drag and drop a zip folder containing a PSA file and your product image files named by UPC. We're here to help! We will try to pull any missing images from our database but please review these to ensure they have the most up-to-date packaging. ")]):_c('p',[_vm._v(" Simply drag and drop "+_vm._s(_vm.psaOrZipLabel)+" file or browse file on your computer ")])],1)]),_c('div',{staticClass:"upload-item"},[_c('div',{staticClass:"upload-item__circle"},[_c('span',{staticClass:"upload-item__circle-label",class:{
            'upload-item__circle-label--enabled': _vm.uploadedFile === 'psa-zip',
          }},[_vm._v(_vm._s(_vm.psaOrZipLabel))]),_c('font-awesome-icon',{attrs:{"icon":"file"}})],1),_c('p',[_vm._v(_vm._s(_vm.statusMessage))]),(_vm.showEmailBtn)?_c('a-button',{staticClass:"b-radius-40 mr-10",attrs:{"type":"primary"},on:{"click":_vm.sendEmailNotification}},[_vm._v(" Send Email on Completion ")]):_vm._e(),(_vm.uploadingFile === 'psa-zip' || _vm.processShelfdataStarted)?_c('Loader',{staticClass:"space-0",attrs:{"text":""}}):(_vm.uploadedFile === 'psa-zip' && !_vm.processShelfdataStarted)?_c('font-awesome-icon',{attrs:{"icon":"check-circle"}}):_c('font-awesome-icon',{attrs:{"icon":"minus-circle"}}),(_vm.uploadingFile === 'psa-zip' || _vm.processShelfdataStarted)?_c('i',{staticClass:"upload-item__loading-line"}):_vm._e()],1)],1):_vm._e(),(_vm.showMissingUPC)?_c('div',{staticClass:"upload__inner upload__inner--missing-upc"},[_c('div',{staticClass:"missing-upc"},[_c('div',{staticClass:"flex justify-between"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Upload Summary Details")]),_c('div',{staticClass:"flex gap-10"},[(_vm.psaDataFailed && _vm.missingFilesType === 'psa')?_c('a-button',{staticClass:"b-radius-40 dark-disable",attrs:{"type":"primary","disabled":_vm.processShelfdataStarted},on:{"click":_vm.getCsv}},[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"download"}}),_vm._v(" Download CSV ")],1):_vm._e(),_c('a-button',{staticClass:"b-radius-40 dark-disable",attrs:{"type":"primary","disabled":_vm.processShelfdataStarted},on:{"click":function($event){(_vm.activeUploadForm = 'zip'),
                (_vm.uploadedFile = ''),
                (_vm.showMissingUPC = false)}}},[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"file-upload"}}),_vm._v(" Upload ZIP ")],1),(_vm.missingFilesType === 'psa')?_c('a-button',{attrs:{"type":"dark","disabled":_vm.processShelfdataStarted},on:{"click":function($event){(_vm.activeUploadForm = 'csv'),
                (_vm.uploadedFile = ''),
                (_vm.showMissingUPC = false)}}},[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"file-upload"}}),_vm._v(" Upload CSV ")],1):_c('a-button',{attrs:{"type":"dark","disabled":_vm.processShelfdataStarted},on:{"click":function($event){(_vm.activeUploadForm = 'psa'),
                (_vm.uploadedFile = ''),
                (_vm.showMissingUPC = false)}}},[_c('font-awesome-icon',{staticClass:"mr-5",attrs:{"icon":"file-upload"}}),_vm._v(" Upload PSA ")],1),_c('a-popover',{attrs:{"visible":_vm.showEmailBtn,"trigger":"click","overlayClassName":"ant-popover-dark"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('a',{on:{"click":_vm.sendEmailNotification}},[_vm._v("Send Email on Completion")])]},proxy:true}],null,false,4199923785)},[_c('a-button',{staticStyle:{"width":"80px"},attrs:{"type":"dark","disabled":_vm.processShelfdataStarted},on:{"click":_vm.proceedWithoutImages}},[(_vm.processShelfdataStarted)?_c('Loader',{staticClass:"space-0",attrs:{"text":""}}):_c('span',[_vm._v("Skip "),_c('font-awesome-icon',{staticClass:"ml-5",attrs:{"icon":"arrow-right"}})],1)],1)],1)],1)]),_c('div',{staticClass:"missing-upc__statistics"},[_c('div',{staticClass:"\n            missing-upc__statistics-item missing-upc__statistics-item--db\n          ",class:{
            'missing-upc__statistics-item--disabled':
              _vm.pulledDbUPC.length === 0,
          }},[_c('h3',[_vm._v("Pulled from database")]),_c('span',[_vm._v(_vm._s(_vm.pulledDbUPC.length))]),_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1),_c('div',{staticClass:"missing-upc__statistics-item",class:{
            'missing-upc__statistics-item--disabled':
              _vm.filteredMissingUPC.length === 0,
          }},[_c('h3',[_vm._v("Total Missing UPC")]),_c('span',[_vm._v(_vm._s(_vm.filteredMissingUPC.length))]),_c('font-awesome-icon',{attrs:{"icon":"minus-circle"}})],1),_c('div',{staticClass:"\n            missing-upc__statistics-item missing-upc__statistics-item--match\n          ",class:{
            'missing-upc__statistics-item--disabled':
              _vm.matchedFiles.length === 0,
          }},[_c('h3',[_vm._v("Total Matched Files")]),_c('span',[_vm._v(_vm._s(_vm.matchedFiles.length))]),_c('font-awesome-icon',{attrs:{"icon":"check-circle"}})],1),_c('div',{staticClass:"\n            missing-upc__statistics-item missing-upc__statistics-item--unmatch\n          ",class:{
            'missing-upc__statistics-item--disabled':
              _vm.unMatchedFiles.length === 0,
          }},[_c('h3',[_vm._v("Total Unmatched Files")]),_c('span',[_vm._v(_vm._s(_vm.unMatchedFiles.length))]),_c('font-awesome-icon',{attrs:{"icon":"exclamation-triangle"}})],1)]),_c('div',{staticClass:"missing-upc__list"},[_c('div',{staticClass:"missing-upc__list-cols missing-upc__list-cols--db"},_vm._l((_vm.pulledDbUPC),function(item){return _c('div',{key:item.UPC,staticClass:"missing-upc__list-item"},[_vm._v(" "+_vm._s(item.UPC)+" ")])}),0),_c('div',{staticClass:"missing-upc__list-cols"},_vm._l((_vm.filteredMissingUPC),function(item){return _c('div',{key:item.UPC,staticClass:"missing-upc__list-item"},[_vm._v(" "+_vm._s(item.UPC)+" ")])}),0),_c('div',{staticClass:"missing-upc__list-cols missing-upc__list-cols--match"},_vm._l((_vm.matchedFiles),function(item){return _c('div',{key:item.FileName,staticClass:"missing-upc__list-item"},[_vm._v(" "+_vm._s(item.FileName)+" ")])}),0),_c('div',{staticClass:"missing-upc__list-cols missing-upc__list-cols--unmatch"},_vm._l((_vm.unMatchedFiles),function(item){return _c('div',{key:item.FileName,staticClass:"missing-upc__list-item"},[_vm._v(" "+_vm._s(item.FileName)+" ")])}),0)])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',[_c('span',[_vm._v("or")])])}]

export { render, staticRenderFns }