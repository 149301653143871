<template>
  <div class="active-shelf">
    <h2>Active Shelf</h2>
    <div class="active-shelf__inner">
      <div class="active-shelf__image">
        <img
          :src="activeShelf ? activeShelf.imageURL || activeShelf.image : ''"
          alt=""
        />
      </div>
      <div class="active-shelf__info">
        <p>
          <icon-base
            :width="24"
            :height="24"
            :viewBox1="24"
            :viewBox2="24"
            iconName="box"
            iconColor="#fff"
            ><icon-box
          /></icon-base>
          {{ activeShelf ? activeShelf.Name || activeShelf.shelfName : "/" }}
        </p>
        <p>
          <icon-base
            :width="24"
            :height="24"
            :viewBox1="24"
            :viewBox2="24"
            iconName="cart"
            iconColor="#fff"
            ><icon-cart
          /></icon-base>
          {{ activeShelf ? activeShelf.numberOfProducts : "/" }} Products
        </p>
        <p>
          <icon-base
            :width="24"
            :height="24"
            :viewBox1="24"
            :viewBox2="24"
            iconName="calendar"
            iconColor="#fff"
            ><icon-calendar
          /></icon-base>

          {{ activeShelf && activeShelf.date ? activeShelf.date : "/" }}
        </p>
        <div
          class="active-shelf__info-cta"
          v-if="showEdit && surveyType !== 'packtestextend'"
          @click="$emit('editShelf')"
        >
          Edit
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/general/IconBase.vue";
import IconCalendar from "@/components/icons/IconCalendar.vue";
import IconCart from "@/components/icons/IconCart.vue";
import IconBox from "@/components/icons/IconBox.vue";
export default {
  name: "ActiveShelf",
  components: {
    IconBase,
    IconCalendar,
    IconCart,
    IconBox,
  },
  props: {
    activeShelf: {
      type: Object,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showRetailer: {
      type: Boolean,
      default: false,
    },
    surveyType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  mounted() {},
  created() {},
  activated() {},
};
</script>

<style lang="scss" scoped>
.active-shelf {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  height: 100%;
  h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.4px;
    margin-bottom: 18px;
  }
  &__inner {
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  &__image {
    width: 100%;
    height: 99px;
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      border-radius: 8px;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info {
    padding: 22px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    p {
      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.32px;
      margin-bottom: 14px;
      position: relative;

      padding-left: 33px;
      svg {
        font-size: 22px;
        position: absolute;
        left: 0;
        top: -2px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__info-cta {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
  }
  &__retailer {
    font-size: 13px;
    line-height: 14px;
    padding-top: 1px;
  }
}
</style>
