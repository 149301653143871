<template>
  <div>
    <div class="large-field large-field--dark w-full">
      <label>Insert currency (up to 2 characters)</label>
      <a-input class="ant-input--dark w-full" v-model="currency" />
    </div>
    <div class="flex justify-end gap-10">
      <a-button @click="$emit('close-change-currency')">Cancel</a-button>
      <a-button
        type="primary"
        @click="save"
        :disabled="currency.trim() === '' || currency.trim().length > 2"
        >Save</a-button
      >
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
export default {
  name: "ChangeCurrency",
  props: {
    shelfId: {
      type: String,
    },
  },
  data() {
    return {
      currency: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    save() {
      this.loading = true;
      if (this.currency.trim() === "" || this.currency.trim().length > 2) {
        return;
      }

      const data = {
        wsName: "UpdateCurrencySymbol",
        data: {
          surveyId: this.shelfId,
          currencySymbol: this.currency,
        },
      };
      const send = new Promise((resolve) => {
        wsUtils.ApiPost(data, resolve);
      });

      send.then((data) => {
        this.loading = false;
        if (data.success) {
          this.$emit("set-currency", this.currency.trim());
          this.$emit("close-change-currency");
          this.currency = "";
          this.$notification["success"]({
            message: "Well done!",
            description: "The currency has been saved.",
            placement: "bottomLeft",
            duration: 5,
          });
        } else {
          this.$notification["error"]({
            message: "Error!",
            description:
              "Something was wrong, please try again or contact our support.",
            placement: "bottomLeft",
            duration: 5,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
