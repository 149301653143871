<template>
  <div class="shelf-builder__loading text-center">
    <div class="products-loader">
      <ul class="products-loader__list">
        <li class="products-loader__list-item first"></li>
        <li class="products-loader__list-item second"></li>
        <li class="products-loader__list-item third"></li>
        <li class="products-loader__list-item fourth"></li>
        <li class="products-loader__list-item fifth"></li>
        <li class="products-loader__list-item sixth"></li>
      </ul>
      <div class="products-loader__shelf"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    allShelvesLength: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.shelf-builder {
  &__loading {
    position: fixed;
    top: 0;
    left: 96px;
    width: 100%;
    bottom: 0;
    background: #1a1b1d;
    color: #fff;
    z-index: 998;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .loader {
      margin-top: 30px;
      font-size: 14px;
      ::v-deep .circle-loader {
        display: none;
      }
    }
  }
}

$thickness: 5px;
$duration: 2800;
$delay: $duration/6;

@mixin dotts-list($size, $dot, $base, $accent) {
  background: $base;
  background-image: radial-gradient($accent $dot, transparent 0);
  background-size: $size $size;
  background-position: 0 -2.5px;
}

.products-loader {
  position: relative;
  transform: scale(0.8);
  &__list {
    margin: 0 auto;
    width: 300px;
    padding: 0;
  }
  &__list-item {
    position: absolute;
    top: -70px;
    box-sizing: border-box;
    list-style: none;
    width: 60px;
    height: 70px;
    opacity: 0;
    background-color: var(--blue);
    border: 4px solid #fff;
    transform-origin: bottom left;
    transform: translateX(300px);
    animation: products-move #{$duration}ms linear infinite;

    &.first {
      top: -100px;
      height: 100px;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: $thickness;
        background-color: #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.second,
    &.fifth {
      &:before,
      &:after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 10px;
        left: 0;
        width: 100%;
        height: $thickness * 3.5;
        border-top: $thickness solid #fff;
        border-bottom: $thickness solid #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.third {
      &:before,
      &:after {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 10px;
        left: 9px;
        width: 15px;
        height: 15px;
        border: $thickness solid #fff;
      }

      &:after {
        top: initial;
        bottom: 10px;
      }
    }

    &.fourth {
      top: -110px;
      height: 110px;

      &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        top: 46px;
        left: 0;
        width: 100%;
        height: $thickness * 3.5;
        border-top: $thickness solid #fff;
        border-bottom: $thickness solid #fff;
      }
    }

    &.fifth {
      top: -100px;
      height: 100px;
    }

    &.sixth {
      top: -116px;
      height: 115px;

      &:before {
        box-sizing: border-box;
        content: "";
        position: absolute;
        bottom: 31px;
        left: 0px;
        width: 100%;
        height: $thickness;
        background-color: #fff;
      }
    }

    &:nth-child(2) {
      animation-delay: #{$delay * 1}ms;
    }

    &:nth-child(3) {
      animation-delay: #{$delay * 2}ms;
    }

    &:nth-child(4) {
      animation-delay: #{$delay * 3}ms;
    }

    &:nth-child(5) {
      animation-delay: #{$delay * 4}ms;
    }

    &:nth-child(6) {
      animation-delay: #{$delay * 5}ms;
    }
  }
  &__shelf {
    width: 300px;
    height: $thickness;
    margin: 0 auto;
    background-color: #fff;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      @include dotts-list(10px, 30%, #242527, rgba(255, 255, 255, 1));
      top: 200%;
      left: 5%;
      animation: dotts-move #{$duration/10}ms linear infinite;
    }

    &:after {
      top: 400%;
      left: 7.5%;
    }
  }
}

@keyframes dotts-move {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 10px;
  }
}

@keyframes products-move {
  0% {
    opacity: 0;
    transform: translateX(300px) rotateZ(0deg) scaleY(1);
  }

  6.5% {
    transform: translateX(279.5px) rotateZ(0deg) scaleY(1.1);
  }

  8.8% {
    transform: translateX(273.6px) rotateZ(0deg) scaleY(1);
  }

  10% {
    opacity: 1;
    transform: translateX(270px) rotateZ(0deg);
  }

  17.6% {
    transform: translateX(247.2px) rotateZ(-30deg);
  }

  45% {
    transform: translateX(165px) rotateZ(-30deg);
  }

  49.5% {
    transform: translateX(151.5px) rotateZ(-45deg);
  }

  61.5% {
    transform: translateX(115.5px) rotateZ(-45deg);
  }

  67% {
    transform: translateX(99px) rotateZ(-60deg);
  }

  76% {
    transform: translateX(72px) rotateZ(-60deg);
  }

  83.5% {
    opacity: 1;
    transform: translateX(49.5px) rotateZ(-90deg);
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    transform: translateX(0px) rotateZ(-90deg);
  }
}
</style>
