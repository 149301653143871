<template>
  <ul class="tabs">
    <li
      v-for="item in allShelvesLength"
      :key="item"
      @click="$emit('active-shelf-index', item - 1)"
      :class="{ active: activeShelfIndex === item - 1 }"
    >
      <a-tooltip>
        <template slot="title">
          <span v-if="allShelvesUrlCheck[item - 1]"
            >Shelf image is confirmed.</span
          >
          <span v-else>Shelf image is not confirmed.</span>
        </template>

        <font-awesome-icon icon="image" v-if="!allShelvesUrlCheck[item - 1]" />
      </a-tooltip>
      <span v-if="item - 1 === 0">Control</span>
      <span v-else>Test {{ item - 1 }}</span>
    </li>
    <li @click="$emit('add-test')">
      <font-awesome-icon icon="plus" /> Add Test {{ allShelvesLength }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    allShelvesLength: {
      type: Number,
    },
    activeShelfIndex: {
      type: Number,
    },
    allShelvesUrlCheck: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  color: #fff;
  padding: 0;
  margin: 0 0 0 20px;
  flex: 1;
  overflow-x: auto;
  gap: 10px;

  li {
    padding: 0px 15px;
    height: 32px;
    border: 1px solid #4c4d4e;
    background: #3c3d3e;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 40px;
    font-weight: 500;
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      border-bottom: 1px solid var(#4c4d4e);
      content: "";
      opacity: 0;
      transition: 0.3s ease opacity;
    }
    &.active,
    &:hover {
      background: #3f4042;
      border-color: var(--purpleLight);
    }
    > svg {
      font-size: 12px;
      margin-right: 5px;
    }
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      svg {
        font-size: 12px;
        color: #f8caca;
      }
    }
    &:last-child {
      background: transparent;
      border-color: transparent !important;
    }
  }
}
</style>
